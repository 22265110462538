export default {
  $primaryOrange: '#F89C1C',
  $primaryRed: '#D0021B',
  $primaryBlue: '#0084FF',
  $primaryGreen: '#56B900',
  $primaryBlack: '#333333',
  $primary: '#346e7a',
  $skyBlue: '#0084FF',
  $midGray: '#999999',
  $lightGray: '#ededed',
  $darkGray: '#515151',
  $darkRed: '#B12704',
};
