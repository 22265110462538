import Colors from "../constants/Colors";

export default {
    24: {
        name: {
            ar: "24 شعرة",
            en: "24 Port"
        },
        color: "red",
        index: 0,
    },
    48: {
        name: {
            ar: "48 شعرة",
            en: "48 Port"
        },
        color: "green",
        index: 1,
    },
    96: {
        name: {
            ar: "96 شعرة",
            en: "96 Port"
        },
        color: Colors.$primaryOrange,
        index: 2,
    },
    144: {
        name: {
            ar: "144 شعرة",
            en: "144 Port"
        },
        color: "transparent",
        index: 3
    },
}