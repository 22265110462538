import Colors from "../constants/Colors";

export default {
    0: {
        name: {
            ar: "أدمن",
            en: "Admin"
        },
        color: Colors.$primaryBlack
    },
    1: {
        name: {
            ar: "مهندس",
            en: "Engineer"
        },
        color: Colors.$midGray
    },
    2: {
        name: {
            ar: "فني",
            en: "Technician"
        },
        color: Colors.$primary
    },
}