import React from "react";
import animationData from "../../assets/animations/welcome.json";
import Lottie from "react-lottie";
export default class LottieSideBar extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    return (
      <div
        style={{
          zIndex: 100,
        }}
        className="cursor-default relative"
      >
        <div style={{
          zIndex: 101
        }} className="absolute inset-0 h-full w-full">
        </div>
        <div className=" inset-0">
          <Lottie
            options={defaultOptions}
            height={200}
            width={200}
            autoPlay
            loop
          />
        </div>
        {/* <div className="font-semibold text-primaryBlack text-center w-full mt-3">
          Welcome Back!
        </div>
        <div className="font-bold text-primaryBlack text-center text-xl w-full -mt-1">
            Mohammad Fannous
        </div> */}
      </div>
    );
  }
}
