import Colors from "../constants/Colors";

export default {
    0: {
        name: { en: "YanarTech" },
        color: Colors.$primary,
        text: "white"
    },
    1: {
        name: { en: "Umnieh" },
        color: "#E0E61D",

        text: Colors.$primaryBlack
    },
    2: {
        name: { en: "JEPCO" },
        color: Colors.$primaryBlue,
        text: "white"
    },
}