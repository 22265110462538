import request from ".";

export const jobsApi = {
    getById: ({ id }) => request.get(`jobs/${id}`),
    create: (data) => request.post("/jobs", { ...data }),
    update: ({ id, data }) => request.put(`jobs/${id}`, { ...data }),
    get: () => request.get("/jobs"),
    getMyJobs: () => request.get("/jobs/my_jobs"),
    getCreatedJobs: () => request.get("/jobs/created_jobs"),
    getCompleted: () => request.get("/jobs?status=2"),
    getDelivered:() => request.get("/jobs?status=3"),
    start: ({ id }) => request.post(`/jobs/start_job/${id}`, {}),
    finish: ({ id, data }) => request.post(`/jobs/finish_job/${id}`, { ...data }),
    delete: ({ id }) => request.delete(`/jobs/${id}`)
}