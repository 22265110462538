import Colors from "../constants/Colors";

export default {
    0: {
        name: {
            ar: "غير فعاله",
            en: "Inactive"
        },
        color: "red",
        textColor: "red",
    },
    1: {
        name: {
            ar: "فعاله",
            en: "Active"
        },
        color: "green",
        textColor: "green",
    },
    2: {
        name: {
            ar: "يحتاج الى صيانه",
            en: "Work Required"
        },
        color: Colors.$primaryOrange,
        textColor: Colors.$primaryOrange,
    },
    3: {
        name: {
            ar: "غير مركب",
            en: "Not Installed"
        },
        color: "transparent",
        textColor: Colors.$darkGray,
    },
    4: {
        name: {
            ar: "تحت العمل",
            en: "Job Assignd"
        },
        color: "blue",
        textColor: "blue",
    },
    5: {
        name: {
            ar: "خارج الخدمه",
            en: "Out of service"
        },
        color: "black",
        textColor: "black",
    },
}