import React from "react";
import { useOutlet, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { IoMdHome, IoMdPeople, IoMdBuild, IoMdToday, IoMdClipboard, IoMdDoneAll, IoMdEgg } from "react-icons/io"
import Colors from "../../../lib/constants/Colors";
import LottieSideBar from "../../../components/LottieSideBar"
import Clock from "../../../components/Clock";
import request from "../../../lib/api";
import _ from "lodash";
import Button from "../../../components/Button";
import { unitsApi } from "../../../lib/api/units";
const SideBarContainer = styled.div.attrs({ className: "rounded-lg border fixed left-0 w-64 flex flex-col overflow-y-scroll" })`
height:100vh`

const SideBarHeader = styled.div.attrs({ className: "text-xl font-semibold  mx-6 mt-6 border-b pb-3 text-black" })`
`

const MenuContainer = styled.div.attrs({ className: "py-2 border-b pl-1" })`
`

const MenuItem = styled.div.attrs({ className: "flex items-center pl-3 py-3 rounded-md cursor-pointer m-2" })`
 transition: all 0.5s;
 color:${Colors.$primaryBlack};
 &:hover{
     background-color:rgb(0,74,192);
     color:white;
 }
`

const MenuIcon = styled.div.attrs({ className: "flex" })`

`

const MenuTitle = styled.div.attrs({ className: "flex ml-3  " })`
font-weight:600;
`


const data = [
    {
        "slug": "اسكان موظفي الجامعة_الجبيه",
        "serial_number": "JEP-FSC-19-224",
        "installation_method": "2",
        "location": "32°00'41.7\" 35°52'43.4\""
    },
    {
        "slug": "إبراهيم عبد المعطي",
        "serial_number": "JEP-FSC-19-090",
        "installation_method": "2",
        "location": "32°00'34.5\" 35°52'43.6\""
    },
    {
        "slug": "الشركة الرخامية",
        "serial_number": "JEP-FSC-19-288",
        "installation_method": "2",
        "location": "32°00'53.7\" 35°53'21.9\""
    },
    {
        "slug": "اسكان المهندسين 1_ام حليليف",
        "serial_number": "JEP-FSC-19-300",
        "installation_method": "2",
        "location": "32°00'37.1\" 35°53'58.5\""
    },
    {
        "slug": "اسكان المهندسين 2_ام حليليف",
        "serial_number": "JEP-FSC-19-232",
        "installation_method": "0",
        "location": "32°00'43.3\" 35°54'12.1\""
    },
    {
        "slug": "الشقيقات",
        "serial_number": "JEP-FSC-19-108",
        "installation_method": "0",
        "location": "32°00'06.2\" 35°53'26.0\""
    },
    {
        "slug": "الهويدي",
        "serial_number": "JEP-FSC-19-168",
        "installation_method": "0",
        "location": "32°00'10.6\" 35°53'15.1\""
    },
    {
        "slug": "عصام الحاج عيد_ام حليليفة",
        "serial_number": "JEP-FSC-19-233",
        "installation_method": "2",
        "location": "32°00'25.2\" 35°53'21.1\""
    },
    {
        "slug": "الرمضاء",
        "serial_number": "JEP-FSC-19-288",
        "installation_method": "0",
        "location": "32°00'30.4\" 35°53'17.2\""
    },
    {
        "slug": "التوتنجي والزركوشي",
        "serial_number": "JEP-FSC-19-138",
        "installation_method": "1",
        "location": "32°00'09.9\" 35°53'27.9\""
    },
    {
        "slug": "يوسف عليان_الجبيهه",
        "serial_number": "JEP-FSC-19-188",
        "installation_method": "2",
        "location": "32°00'44.6\" 35°53'34.5\""
    },
    {
        "slug": "أم حليليفه 2",
        "serial_number": "JEP-FSC-19-140",
        "installation_method": "0",
        "location": "32°00'44.3\" 35°53'18.5\""
    },
    {
        "slug": "الحاج ذيب",
        "serial_number": "JEP-FSC-19-118",
        "installation_method": "0",
        "location": "32°00'28.0\" 35°53'04.5\""
    },
    {
        "slug": "مدرسة المنهل",
        "serial_number": "JEP-FSC-19-174",
        "installation_method": "2",
        "location": "32°00'49.7\" 35°53'04.5\""
    },
    {
        "slug": "أبو الوفا ومرشد",
        "serial_number": "JEP-FSC-19-210",
        "installation_method": "2",
        "location": "32°00'37.9\" 35°53'08.4\""
    },
    {
        "slug": "خلدون طاشمان",
        "serial_number": "JEP-FSC-19-179",
        "installation_method": "0",
        "location": "32°00'37.1\" 35°53'39.3\""
    },
    {
        "slug": "العمارة الملوكية 1",
        "serial_number": "JEP-FSC-19-301",
        "installation_method": "0",
        "location": "32°00'47.9\" 35°53'16.4\""
    },
    {
        "slug": "اسماء و علقم",
        "serial_number": "JEP-FSC-19-170",
        "installation_method": "2",
        "location": "32°01'02.2\" 35°53'18.2\""
    },
    {
        "slug": "الكلية العلمية_القسم الأجنبي",
        "serial_number": "JEP-BEP1",
        "installation_method": "3",
        "location": "32°01'04.0\" 35°53'30.3\""
    },
    {
        "slug": "اسكان الأندلس",
        "serial_number": "JEP-FSC-19-276",
        "installation_method": "2",
        "location": "32°00'29.1\" 35°53'01.1\""
    },
    {
        "slug": "استملاك أم حليليفة",
        "serial_number": "JEP-FSC-19-115",
        "installation_method": "2",
        "location": "32°00'17.0\" 35°53'22.0\""
    },
    {
        "slug": "مدرسة اسكان الجامعة",
        "serial_number": "JEP-FSC-19-131",
        "installation_method": "2",
        "location": "32°00'53.7\" 35°52'38.2\""
    },
    {
        "slug": "القرن الثاني و العشرين",
        "serial_number": "JEP-BEP2",
        "installation_method": "3",
        "location": "32°00'42.4\" 35°53'42.0\""
    },
    {
        "slug": "مفاتيح زريقات_الجبيهه",
        "serial_number": "JEP-BEP3",
        "installation_method": "3",
        "location": "32°00'36.9\" 35°52'53.9\""
    }
]
const uploadUnits = () => {
    const convertDegreesToDecimal = (value) => {
        let latlong = value.split(" ");
        let easting = parseFloat(latlong[0].split("°")[0]) + parseFloat(latlong[0].split("°")[1].slice(0, 2)) / 60 + parseFloat((latlong[0].split("'")[1].slice(0, 3))) / 3600
        let northing = parseFloat(latlong[1].split("°")[0]) + parseFloat(latlong[1].split("°")[1].slice(0, 2)) / 60 + parseFloat((latlong[1].split("'")[1].slice(0, 3))) / 3600

        return [easting, northing]
    }
    data.map(async unit => {
        // let L = unit.location.split(" ");
        await unitsApi.create({
            ...unit,
            location: {
                type: "Point",
                coordinates: [...convertDegreesToDecimal(unit.location)],
            },
            unit_type: [{ type: 24, quantity: 1 }],
            description: " ",
            status: 1,
            unit_access: 2
        })
    }
    )
}

const menu = [
    {
        icon: <IoMdHome size={22} />,
        title: "Home",
        path: ""
    },
    {
        icon: <IoMdPeople size={22} />,
        title: "Users",
        path: "/users"
    },
    {
        icon: <IoMdBuild size={22} />,
        title: "Jobs",
        path: "/jobs"
    },
    {
        icon: <IoMdToday size={22} />,
        title: "Units",
        path: "/units"
    },
    {
        icon: <IoMdClipboard size={22} />,
        title: "Completed",
        path: "/completed"
    },
    {
        icon: <IoMdDoneAll size={22} />,
        title: "Delivered",
        path: "/delivered"
    },
    // {
    //     icon: <IoMdEgg size={22} />,
    //     title: "Test",
    //     path: "/testing"
    // },

]

export default function Main() {

    const logout = () => {
        request.removeSession();
        navigate("/login");
    }
    const navigate = useNavigate();

    return (
        <SideBarContainer>

            <SideBarHeader>
                YANAR <span className="text-primary">TECH</span>
            </SideBarHeader>

            <MenuContainer>
                {menu.map(item => {
                    return (
                        <MenuItem onClick={() => navigate(item.path)}>
                            <MenuIcon>
                                {item.icon}
                            </MenuIcon>
                            <MenuTitle>
                                {item.title}
                            </MenuTitle>
                        </MenuItem>
                    )
                })}
            </MenuContainer>
            <div className="border shadow mt-4 rounded-md m-2 pb-2">
                <LottieSideBar />
                <div className="mt-6">
                    <Clock />
                </div>
            </div>

            <div className="mt-auto  text-center w-full text-sm text-midGray">
                {`Signed in as ${_.capitalize(_.get(JSON.parse(window.localStorage.getItem("USER")), 'name.en'))}`}
            </div>
            <div
                className=" mb-32 text-center border-b">
                <Button onPress={logout} type={"danger"}>
                    Logout
                    </Button>
            </div>



        </SideBarContainer>
    );
}
