import React from "react";

interface Props {
    children;
}

export default function (props: Props) {
    return (
        <div style={{ height: 350}} className="shadow bg-white rounded-md bg- p-3">
            {props.children}
        </div>
    )
}