import Main from "../layouts/Main";
import React from "react";
import Home from "../pages/Home";
import Jobs from "../pages/Jobs";
import Units from "../pages/Units";
import Users from "../pages/Users";

import Guest from "../pages/Guest";
import Completed from "../pages/Completed";
import Delivered from "../pages/Delivered";
import Test from "../pages/Test";

export default [
  {
    path: "/*",
    element: <Main />,
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: "jobs",
        element: <Jobs />,
      },
      {
        path: "units",
        element: <Units />,
      },
      {
        path: "/users",
        element: <Users />,
      },
      {
        path: "/completed",
        element: <Completed />,
      },
      {
        path: "/delivered",
        element: <Delivered />,
      },
      {
        path: "/test",
        element: <Test onUpload={()=>{}} />,
      },
    ],
  },
  {
    path: "/login",
    element: <Guest />,
  },
];
