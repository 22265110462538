import React from "react";
import { subtleHeading } from '@atlaskit/theme/colors';
import { fontSize, gridSize } from '@atlaskit/theme/constants';
import { headingSizes } from '@atlaskit/theme/typography';
import Textfield from '@atlaskit/textfield';


interface Props {
    name;
    value;
    onChange;
    label?;
    secureEntry?;
    error?;
    placeHolder?;
}

export default function (props: Props) {
    return (
        <div className={`w-full mb-2`}>
            <label
                style={{
                    fontSize: `${headingSizes.h200.size / fontSize()}em`,
                    fontStyle: 'inherit',
                    lineHeight: `${headingSizes.h200.lineHeight /
                        headingSizes.h200.size}`,
                    color: `${subtleHeading()}`,
                    fontWeight: 600,
                    marginTop: `${gridSize() * 2}px`,
                }}
            >
                {props.label}
            </label>
            <Textfield
                placeholder={props.placeHolder}
                type={props.secureEntry ? "password" : "text"}
                onChange={props.onChange} value={props.value} name={props.name} />
            {props.error && (<div className="pt-1 text-sm text-darkRed">
                {props.error}
            </div>)}
        </div>
    )
}