export default [
    {
        label: "24 Port",
        value: 24
    },
    {
        label: "48 Port",
        value: 48
    },
    {
        label: "96 Port",
        value: 96
    },
    {
        label: "144 Port",
        value: 144
    },
]
