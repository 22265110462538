import request from ".";

export const unitsApi = {
    get: ({ query }) => request.get("/units", { params: { serial: query } }),
    searchBySerial: ({ query }) => request.get("/units/serial_number", { params: { query } }),
    getById: ({ id }) => request.get(`/units/${id}`),
    create: (data) => request.post("/units", {
        ...data
    }),
    bulkCreate: (data) => request.post("/units/bulk", data),
    getJobs: ({ id }) => request.get(`/units/unit_jobs/${id}`),
    update: ({ data, id }) => request.put(`/units/${id}`, { ...data }),
    delete: ({ id }) => request.delete(`/units/${id}`)
}

