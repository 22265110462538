import React, { useEffect, useState } from "react";
import { useOutlet, useNavigate } from "react-router-dom";
import SideBar from "./SideBar"
import styled from "styled-components";
import request from "../../lib/api";
import _ from "lodash"

const Content = styled.div.attrs({ className: " rounded-md ml-64" })`
box-shadow: 0 0 12px rgba(0,0,0,0.2);
flex-grow:1;
min-height:96vh;
`
export default function Main() {



  const navigate = useNavigate();
  const [user, setUser] = useState<any>(null);
  const userRegulator = async () => {
    try {
      let token = window.localStorage.getItem("AUTH");
      console.log("token", token);
      if (_.isNull(token) || _.isUndefined(token)) {
        navigate("/login");
      } else {
        let user = window.localStorage.getItem("USER") as any;
        setUser(user);
      }
    } catch (e) {
      navigate("/login");
    }
  }

  useEffect(() => {
    userRegulator()
  }, [])

  return (
    <>
      <div
        className="flex ">
        <SideBar />
        <div className="p-4 w-full h-full">
          <Content>
            <div className="p-6">
              {useOutlet()}
            </div>
          </Content>
          <div className="ml-64 text-sm border-t pt-3 text-center text-darkGray">
            All Rights Reserved | YanarTech 2020   </div>
          </div>
      </div>
    </>
  );
}
