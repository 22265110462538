import React, { useState } from "react";
import Wrapper from "../Wrapper";
import styled from "styled-components";
import CountUp from "react-countup";
interface Props {

}

const Wrap = styled.div.attrs({ className: " p-2 w-1/3  " })``

const Container = styled.div.attrs({ className: "shadow p-2 rounded-md h-full " })``

const Tag = styled.div.attrs({ className: "uppercase text-xs text-darkGray " })``

const Value = styled.div.attrs({ className: "text-3xl text-primaryBlack" })``


export default function Stats(props: Props) {


    return (
        <div>
            <Wrapper>
                <div className="font-lg font-semibold">
                    Statistics
                </div>
                <div className="flex py-3 flex-wrap">
                    <Wrap>
                        <Container>
                            <Tag>
                                Sessions
                             </Tag>
                            <Value>
                                <CountUp
                                    end={32}
                                    duration={2.75}
                                />
                            </Value>
                        </Container>
                    </Wrap>
                    <Wrap>
                        <Container>
                            <Tag>
                                Notifications
                             </Tag>
                            <Value>
                                <CountUp
                                    end={2993}
                                    duration={2.75}
                                />
                            </Value>
                        </Container>
                    </Wrap>
                    <Wrap>
                        <Container>
                            <Tag>
                                Logins
                            </Tag>
                            <Value>
                                <CountUp
                                    end={203}
                                    duration={2.75}
                                />
                            </Value>
                        </Container>
                    </Wrap>
                </div>
                <div className="font-lg mt-8 font-semibold">
                    Network
                </div>
                <div className="flex py-3 mt-auto flex-wrap">
                    <Wrap>
                        <Container>
                            <Tag>
                                Database
                             </Tag>
                            <div className="text-primaryGreen font-semibold text-lg mt-2">
                                Connected
                            </div>
                        </Container>
                    </Wrap>
                    <Wrap>
                        <Container>
                            <Tag>
                                Application
                             </Tag>
                            <div className="text-primaryGreen font-semibold text-lg mt-2">
                                Online
                            </div>
                        </Container>
                    </Wrap>
                    <Wrap>
                        <Container>
                            <Tag>
                                Website
                            </Tag>
                            <Value>
                                <div className="text-primaryGreen font-semibold text-lg mt-2">
                                    Active
                            </div>
                            </Value>
                        </Container>
                    </Wrap>
                </div>
            </Wrapper>
        </div >
    )
}