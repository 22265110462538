import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { IoIosClose } from "react-icons/io";
import Select from 'react-select';
import { subtleHeading } from '@atlaskit/theme/colors';
import { fontSize, gridSize } from '@atlaskit/theme/constants';
import { headingSizes } from '@atlaskit/theme/typography';
import unitTypesOptions from "../../lib/data/unitTypesOptions";
import _ from "lodash";

interface Option {
    label: String;
    value: String;
}

interface Props {
    options: Array<any>;
    value;
    onSelect;
    label;
    name;
    error?;
}
const Item = styled.div.attrs({ className: "flex items-center mr-2" })`
background-color:rgb(220,221,222)`

const Title = styled.div.attrs({ className: "text-sm px-1" })``

const Remove = styled.div.attrs({ className: "hover:bg-darkRed hover:opacity-75 h-full  flex justify-center items-center" })`
transition:all 0.5s;
&:hover{
    color:white;
}
`

export default function AdditionSelect(props: Props) {
    // const [options, setOptions] = useState<any>({});
    const [menuOpen, setMenuOpen] = useState(false);
    const [options, setOptions] = useState([...props.value]);


    const onChange = (data) => {
        setMenuOpen(false);
        setOptions(r => [...r, data]);
    }




    const onDelete = (i) => {
        setOptions([...options.slice(0, i), ...options.slice(i + 1)])
    }
    useEffect(() => {
        props.onSelect({ target: { value: options, name: props.name } })
    }, [options])
    return (
        <div className="w-full pb-3">
            <label
                style={{
                    fontSize: `${headingSizes.h200.size / fontSize()}em`,
                    fontStyle: 'inherit',
                    lineHeight: `${headingSizes.h200.lineHeight /
                        headingSizes.h200.size}`,
                    color: `${subtleHeading()}`,
                    fontWeight: 600,
                    marginTop: `${gridSize() * 2}px`,
                }}
            >
                {props.label}
            </label>

            <div className="relative">
                <div
                    onClick={() => setMenuOpen(!menuOpen)}
                    style={{ zIndex: 2, }} className="absolute inset-0 flex items-center rounded-md focus:outline-blue-500 border-2">
                    <div className="mx-2 bg-white flex items-center">
                        {options.length > 0 && options.map((o, i) => {
                            return (
                                <Item>
                                    <Title>
                                        {o.label}
                                    </Title>
                                    <Remove onClick={() => onDelete(i)}>
                                        <IoIosClose size={20} />
                                    </Remove>
                                </Item>
                            )
                        })}
                    </div>
                </div>
                <div style={{ padding: 2 }}>
                    <Select
                        classsName="focus:outline-none"
                        menuIsOpen={menuOpen}
                        name={props.name}
                        isOptionDisabled={((option) => false)}
                        isOptionSelected={((option, options) => false)}
                        limit={"none"}
                        className="basic-single"
                        classNamePrefix="select"
                        isSearchable={true}
                        value={{}}
                        onChange={onChange}
                        options={props.options}
                    />
                </div>
                {props.error && (<div className="pt-1 text-sm text-darkRed">
                    {props.error}
                </div>)}
            </div>
        </div>

    )
}