import React, { useState } from "react";
import { FiEdit } from "react-icons/fi"
import {
    IoMdRemoveCircleOutline
} from "react-icons/io"

interface Props {
    openDelete;
    openEdit;
}

export default function (props: Props) {
 
    return (
        <>
            <div className="flex items-center ">
                <div
                    onClick={props.openEdit}
                    className="hover:opacity-75 cursor-pointer">
                    <FiEdit

                        size={21}
                    />
                </div>
                <div
                    onClick={props.openDelete}
                    className="ml-2 hover:opacity-75 cursor-pointer">
                    <IoMdRemoveCircleOutline
                        size={24}
                    />
                </div>
            </div>
  
        </>
    )
}