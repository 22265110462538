
export default [
    {
        label: "YanarTech",
        value: 0
    },
    {
        label: "Umnieh",
        value: 1
    },
    {
        label: "JEPCO",
        value: 2
    }
]