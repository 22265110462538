import React, { useEffect, useState } from "react";
import Storage from "./Storage";
import Count from "./Count";
import Stats from "./Stats"
import { logsApi } from "../../lib/api/logs";
import styled from "styled-components";
import Colors from "../../lib/constants/Colors";
import moment from "moment";
import DynamicTable from '@atlaskit/dynamic-table';

const CellWrapper = styled.div.attrs({ className: "py-2" })``
export default function () {
    const [logs, setLogs] = useState<any>(null)
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1)

    const fetchLogs = async () => {
        try {
            setLoading(true)
            let { log } = await logsApi.get({ page, limit: 1000 }) as any;
            setLogs(log)
        } catch (e) {
            //do nothing
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchLogs()
    }, [])



    const head = {
        cells: [
            {
                isSortable: false,
                shouldTruncate: false,
                width: 25,
                key: "Level",
                content: "Level",
            },
            {
                isSortable: false,
                shouldTruncate: false,
                width: 55,
                key: "Time",
                content: "Timestamp",
            },
            {
                isSortable: false,
                shouldTruncate: false,
                width: 300,
                key: "log",
                content: "Log",
            },
        ],
        key: "Hello Wrold"
    }



    const levelColor = {
        "error": Colors.$primaryRed,
        "info": Colors.$midGray,
        "warning": Colors.$primaryOrange
    }

    const TransformData = () => {
        return [
            ...logs.map((r, i) => {
                return ({
                    key: `row-${i}-${r._id}`,
                    cells: [

                        {
                            sortKey: r.level,
                            key: `${r.id}${JSON.stringify(r.level)}`,
                            content: (<CellWrapper><div className="flex" ><div className=" uppercase px-1 text-sm rounded-md" style={{ color: levelColor[r.level] }}>{r.level}</div></div></CellWrapper>)
                        },
                        {
                            sortKey: moment(r.timestamp),
                            key: `${r.id}${JSON.stringify(r.timestamp)}`,
                            content: (<CellWrapper><div className="text-darkGray">{moment(r.timestamp).format("DD-MM-YYYY hh:mm:ss")}</div></CellWrapper>)
                        },
                        {
                            key: `${r.id}${JSON.stringify(r.message)}`,
                            content: (<CellWrapper><div className="font-light">{r.message}</div></CellWrapper>),
                        },
                        // {
                        //     key: `${r.id}${"EDIT"}`,
                        //     content: (
                        //         <TableEdit
                        //             openEdit={() => openForm({ item: r })}
                        //             openDelete={() => openDelete({ item: r })}
                        //         />
                        //     )
                        // }
                    ]
                })
            })
        ]
    }







    return (
        <div>
            <div className="flex items-start">
                <div className="w-1/3 p-4">
                    <Storage />
                </div>
                <div className="w-1/3 p-4">
                    <Stats />
                </div>
                <div className="w-1/3 p-4">
                    <Count />
                </div>
            </div>

            <div className="p-4">
                <DynamicTable
                    // caption={"Unit"}
                    head={head}
                    rows={logs ? TransformData() : []}
                    rowsPerPage={12}
                    defaultPage={1}
                    loadingSpinnerSize="large"
                    isLoading={loading}
                    isFixedSize
                    defaultSortKey="term"
                    defaultSortOrder="ASC"
                    onSort={() => console.log('onSort')}
                    // onSetPage={(p) => setPage(p)}
                // page={page}
                />
            </div>
        </div>

    )
}