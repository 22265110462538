import React from "react";
import { subtleHeading } from '@atlaskit/theme/colors';
import { fontSize, gridSize } from '@atlaskit/theme/constants';
import { headingSizes } from '@atlaskit/theme/typography';
import TextArea from '@atlaskit/textarea';


interface Props {
    name;
    value;
    onChange;
    label?;
    error?;
}

export default function (props: Props) {
    return (
        <div className={`w-full mb-2`}>
            <label
                style={{
                    fontSize: `${headingSizes.h200.size / fontSize()}em`,
                    fontStyle: 'inherit',
                    lineHeight: `${headingSizes.h200.lineHeight /
                        headingSizes.h200.size}`,
                    color: `${subtleHeading()}`,
                    fontWeight: 600,
                    marginTop: `${gridSize() * 2}px`,
                }}
            >
                {props.label}
            </label>
            <TextArea
                resize="auto"
                isCompact
                onChange={props.onChange}
                value={props.value}
                name={props.name}
            />
            {props.error && (<div className="pt-1 text-sm text-darkRed">
                {props.error}
            </div>)}
        </div>
    )
}