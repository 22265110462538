import React from "react";
import { jsx } from '@emotion/core';
import Button, { ButtonAppearances } from '@atlaskit/button';






interface Props {
    onPress;
    type: 'default' |
    'primary' |
    'link' |
    'subtle' |
    'subtle-link' |
    'warning' |
    'danger';
    children;
    isLoading?
}

export default function (props: Props) {
    return (
        <div className={`w-full mb-2 mt-3`}>
            <Button
                onClick={props.onPress}
                isLoading={props.isLoading}
                appearance={props.type}>
                {props.children}
            </Button>
        </div>
    )
}