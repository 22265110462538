import React from "react";
import Select from 'react-select';
import { subtleHeading } from '@atlaskit/theme/colors';
import { fontSize, gridSize } from '@atlaskit/theme/constants';
import { headingSizes } from '@atlaskit/theme/typography';
interface Props {
    options: Array<any>;
    value;
    onSelect;
    label;
    name;
}

export default function (props: Props) {
    const onChange = (data) => {
        props.onSelect({ target: { value: data, name: props.name } })
    }
    return (
        <div className="w-full pb-3">
            <label
                style={{
                    fontSize: `${headingSizes.h200.size / fontSize()}em`,
                    fontStyle: 'inherit',
                    lineHeight: `${headingSizes.h200.lineHeight /
                        headingSizes.h200.size}`,
                    color: `${subtleHeading()}`,
                    fontWeight: 600,
                    marginTop: `${gridSize() * 2}px`,
                }}
            >
                {props.label}
            </label>
            <Select
                name="status"
                className="basic-single"
                classNamePrefix="select"
                defaultValue={props.options[0]}
                // isDisabled={false}
                // isLoading={false}
                // isClearable={false}
                // isRtl={false}
                isSearchable={true}
                value={props.value}
                onChange={onChange}
                options={props.options}
            />
        </div>
    )
}