import React from "react";
import { subtleHeading } from '@atlaskit/theme/colors';
import { fontSize, gridSize } from '@atlaskit/theme/constants';
import { headingSizes } from '@atlaskit/theme/typography';
import { DatePicker, DateTimePicker, TimePicker } from '@atlaskit/datetime-picker';

import moment from "moment";
interface Props {
    value: any;
    onChange;
    label;
    name;
    error?;
}

export default function (props: Props) {

    const handleChange = (date) => {
        let data = { target: { name: props.name, value: moment(date).format('MM/DD/YYYY') } }
        console.log(data);
        props.onChange(data)
    }

    return (
        <div className="w-full mb-2">
            <label
                style={{
                    fontSize: `${headingSizes.h200.size / fontSize()}em`,
                    fontStyle: 'inherit',
                    lineHeight: `${headingSizes.h200.lineHeight /
                        headingSizes.h200.size}`,
                    color: `${subtleHeading()}`,
                    fontWeight: 600,
                    marginTop: `${gridSize() * 2}px`,
                }}
            >
                {props.label}
            </label>
            <DatePicker
                id="datepicker-3"
                dateFormat={"MM/DD/YYYY"}
                defaultValue={props.value}
                onChange={handleChange}
            />
            {props.error && (<div className="pt-1 text-sm text-darkRed">
                {props.error}
            </div>)}
        </div>
    )
}