import React from 'react';
import ReactDOM from 'react-dom';
import "./css/tailwind.css";
import App from "./App";
import "./theme/variables.scss";
import "./theme/global.scss";
import * as ServiceWorker from "./serviceWorker";



ReactDOM.render(<App />, document.getElementById("root"));

ServiceWorker.unregister();
