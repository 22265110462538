import Axios, { AxiosResponse, AxiosError, AxiosStatic } from 'axios';
import _ from 'lodash';
import Users from '../../pages/Users';


const prod = 'https://gentle-beyond-95419.herokuapp.com/api';
const stg = 'https://evening-inlet-06169.herokuapp.com/api';


// const STAGING_URL = 'https://sheltered-wave-33101.herokuapp.com/api';
const AUTH_HEADER = "x-auth-token"

const request = Axios as AxiosStatic & {
    setSession: any;
    removeSession: any;
    setUser: any;
    setEnv: any;
};


const onSuccess = function (response: AxiosResponse) {
    console.log('Request Successful!', response);
    return response.data
};

const onError = function (error: AxiosError) {
    if (Axios.isCancel(error)) {
        return Promise.reject(error);
    }
    console.log(error);
    if (error.response) {
        // Request was made but server responded with something
        // other than 2xx
        console.log('FAILED Response:', error.response);
        console.log('FAILED Status:', error.response.status);
        console.log('FAILED Data:', error.response.data);
        console.log('FAILED Headers:', error.response.headers);

        switch (error.response.status) {
            case 401:
                // TODO: revoke local session
                break;
            case 422:
                const errors = _.get(error, 'response.data.errors', null);
                const message = _.get(
                    error,
                    'response.data.message',
                    'Please check your input',
                );

                if (errors) {
                    error.response.data.message = _.get(
                        _.values(errors),
                        '[0][0]',
                        message,
                    );
                    error.response.data['errors'] = errors;
                } else if (message) {
                    error.response.data.message = message;
                }

            default:
                break;
        }
        return Promise.reject(error.response.data);
    } else {
        // Something else happened while setting up the request
        // triggered the error
        console.log('Error Message:', error.message);
    }

    return Promise.reject(error);
};
// request.interceptors.request.use((request) => {
//     console.log("REST", request)
//     return Promise.resolve(request)
// })
request.interceptors.response.use(onSuccess, onError);
request.defaults.headers['accept-language'] = 'en';
request.defaults.headers['x-lang'] = 0;
request.defaults.headers['access-control-allow-origin'] = '*';

request.defaults.baseURL = prod;


request.defaults.headers[AUTH_HEADER] = window.localStorage.getItem("AUTH");

request['setSession'] = function ({ token }) {
    this.defaults.headers[AUTH_HEADER] = token;
    window.localStorage.setItem("AUTH", token);
};

request['setEnv'] = function () {
    const env = window.localStorage.getItem("ENV")
    const URL = !(env !== "production") ? stg : prod;
    request.defaults.baseURL = URL;
};

request['setUser'] = function ({ user }) {
    window.localStorage.setItem("USER", JSON.stringify(user));
}

request['removeSession'] = function () {
    delete this.defaults.headers[AUTH_HEADER];
    window.localStorage.removeItem("AUTH");
    window.localStorage.removeItem("USER");
};




export default request;
