import React, { useState } from "react";
import Wrapper from "../Wrapper";
import { IoIosArrowForward } from "react-icons/io"
import Colors from "../../../lib/constants/Colors";
import { Navigate, useNavigate } from "react-router";
import CountUp from 'react-countup';
import { PieChart } from 'react-minimal-pie-chart';
interface Props {

}

export default function Storage(props: Props) {
    const [value, setValue] = useState(null);
    const navigate = useNavigate()

    // const myData = [{
    //     theta: 10,
    //     label: "label",
    //     title: "Users",
    //     className: "flex w-full"
    // }, {
    //     theta: 63,
    //     label: "label",
    //     title: "Active Jobs",
    // }, {
    //     theta: 10,
    //     label: "label",
    //     title: "Units",
    // }, {
    //     theta: 12,
    //     label: "label",
    //     title: "Reports",
    // }]


    const myData = [
        {
            color: Colors.$primaryGreen,
            title: 'Users',
            value: 57,
        },
        {
            color: Colors.$primaryBlue,
            title: 'Jobs',
            value: 35,
        },
        {
            color: Colors.$primaryOrange,
            title: 'Reports',
            value: 109,
        },
        {
            color: Colors.$primaryRed,
            title: 'Units',
            value: 50,
        }
    ];



    return (
        <div className="relative">
            <Wrapper>
                <div className="font-lg font-semibold">
                    Instances
                </div>
                <div className="relative">
                    <PieChart
                        animate={true}
                        animationDuration={1500}
                        animationEasing="ease-out"
                        // cx={50}
                        // cy={50}
                        style={{
                            height: '278px'
                        }}
                        data={myData}
                        lengthAngle={360}
                        lineWidth={20}
                        onClick={() => { }}
                        onMouseOut={undefined}
                        onMouseOver={(onMouseOverProps) => { console.log(onMouseOverProps) }}
                        paddingAngle={1}
                        radius={50}
                        rounded={false}
                        // ratio={1}
                        startAngle={0}
                    />
                    <div className=" absolute inset-0 flex justify-center items-center flex-col">
                        <div>
                            {myData.map(e => {
                                return (
                                    <div className="items-center flex mb-1">
                                        <div style={{ backgroundColor: e.color }} className="h-2 w-4">
                                        </div>
                                        <div className="text-xs ml-2">
                                            {e.title}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>


            </Wrapper>
        </div >
    )
}