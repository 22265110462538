import React from "react";
import Wrapper from "../Wrapper";
import { IoIosArrowForward } from "react-icons/io"
import Colors from "../../../lib/constants/Colors";
import { Navigate, useNavigate } from "react-router";
import CountUp from 'react-countup';
interface Props {

}




export default function Storage(props: Props) {
    const navigate = useNavigate()
    const storageItems = [
        {
            label: "System",
            percentage: 2,
            color: Colors.$primaryRed,
            value: "1.23 MB",
            // route: "/users"
        },
        {
            label: "Sessions",
            percentage: 3,
            color: Colors.$primaryGreen,
            value: "3.21 MB",
            // route: "/units"
        },
        {
            label: "Logs",
            percentage: 10,
            color: Colors.$primaryOrange,
            value: "2.35 GB",
            // route: "/jobs"
        },
        {
            label: "Data",
            percentage: 30,
            color: Colors.$primaryBlue,
            value: "24.06 GB",
            // route: "/jobs"
        },
        {
            label: "Free",
            percentage: 44,
            color: Colors.$midGray,
            // value: " 25.6 GB",
            route: null
        },
    ]

    return (
        <div>
            <Wrapper>
                <div className="font-lg font-semibold">
                    Storage
                </div>

                <div className="flex py-3  items-start">
                    <div>
                        <div className="text-xs text-darkGray">
                            Used
                    </div>
                        <div className="font-semibold text-2xl">
                            <CountUp
                                end={24.17}
                                decimals={2}
                                duration={2.75}
                                suffix={" GB"}
                            />
                        </div>
                    </div>
                    <div className="ml-auto mr-4">
                        <div className="text-xs text-darkGray">
                            Available
                    </div>
                        <div className="font-semibold text-lg">
                            <CountUp
                                end={25.62}
                                decimals={2}
                                duration={2.75}
                                suffix={" GB"}
                            />
                        </div>
                    </div>
                </div>

                <div className="h-10 flex items-center w-full rounded-md overflow-hidden">

                    {storageItems.map(i => (
                        <div style={{ flex: i.percentage, backgroundColor: i.color }} className="text-sm bg-red-600 h-full justify-center items-center flex text-white">
                            {/* {`${i.percentage}%`} */}
                        </div>
                    ))}
                </div>

                <div className="ml-1 mt-4">
                    {storageItems.map(i => (
                        <div
                            // onClick={() => i.route && navigate(i.route)}
                            className="flex items-center w-full mb-2 justify-between hover:shadow cursor-pointer">
                            <div className="flex items-center">
                                <div style={{ backgroundColor: i.color }} className="rounded-full h-4 w-4">
                                </div>
                                <div className="text-sm ml-3">
                                    {i.label}
                                </div>
                            </div>
                            <div className="flex items-center">
                                <div className="text-xs text-darkGray">
                                    {i.value}
                                </div>
                                {(<div className="ml-2">
                                    <IoIosArrowForward color={Colors.$darkGray} />
                                </div>)}
                            </div>
                        </div>
                    ))}
                </div>


            </Wrapper>
        </div >
    )
}