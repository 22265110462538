import React from "react";
import animationData from "../../assets/animations/loading.json";
import Lottie from "react-lottie";




export default function Loading() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div
      style={{
        zIndex: 98127319273,
      }}
      className="fixed inset-0 opacity-75 flex flex-col justify-center items-center bg-white"
    >
      <Lottie
        options={defaultOptions}
        height={400}
        width={400}
      />
      <div>
        <div className="text-4xl font-bold">
          <span className="text-primary-500">YANAR</span>{" "}
          <span className="">TECH</span>
        </div>
      </div>
    </div>
  );
}
