export default {
    0: {
        name: {
            ar: "تركيب",
            en: "Installation"
        },
    },
    1: {
        name: {
            ar: "",
            en: "Splicing"
        },
    },
    2: {
        name: {
            ar: "تركيب",
            en: "Patching"
        },
    },
    3: {
        name: {
            ar: "صيانه",
            en: "Patching Maintenance"
        },
    },
    4: {
        name: {
            ar: "صيانه",
            en: "Splicing Maintenance"
        },
    },
    5: {
        name: {
            ar: "فحص",
            en: "Testing"
        },
    },
}