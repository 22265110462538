import React, { useState, useEffect } from "react";
import styled from "styled-components";
import DynamicTable from '@atlaskit/dynamic-table';
import { useFormik } from "formik";
import TextField from "../../components/TextField";
import Button from "../../components/Button";
import Loading from "../../components/Loading";
import { userApi } from "../../lib/api/user";
import request from "../../lib/api";
import { useNavigate } from "react-router";
import { jobsApi } from "../../lib/api/jobs";



const Container = styled.div.attrs({ className: "flex justify-center items-center bg-lightGray" })`
height:100vh;
width:100vw;
`;

const SideBarHeader = styled.div.attrs({ className: "text-xl font-semibold mx-6 mt-6 border-b pb-3 text-black" })`
`

const Box = styled.div.attrs({ className: "bg-white border shadow" })`
min-width:25vw;
`;



export default function () {

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const onSubmit = async () => {
        try {
            setLoading(true);
            let { token } = await userApi.login({ ...values }) as any;
            request.setSession({ token });
            let { user: _user } = await userApi.rehydrate() as any;
            if (_user.type !== 0) {
                setError("You have to be an admin in order to login")
                setLoading(false);
                return request.removeSession();
            }
            request.setUser({ user: _user });
            navigate("/");
        } catch (e) {
            setError("Incorrect employee ID or password")
            setTimeout(() => {
            }, 500)
        } finally {
            setTimeout(() => {
                setLoading(false)
            }, 500)
        }
    }

    const { handleChange, values, errors, handleSubmit } = useFormik({
        initialValues: {
            user_id: "",
            password: ""
        },
        onSubmit
    })

    return (
        <Container>
            {loading && <Loading />}
            <Box>
                <SideBarHeader>
                    YANAR <span className="text-primary">TECH</span>
                </SideBarHeader>
                <div className="p-4">
                    <TextField
                        label={"Admin ID"}
                        name={"user_id"}
                        onChange={handleChange}
                        value={values.user_id}
                    />

                    <TextField
                        secureEntry={true}
                        label={"Password"}
                        name={"password"}
                        onChange={handleChange}
                        value={values.password}
                        error={error}
                    />
                    <div className=" flex w-full items-end">
                        <Button
                            onPress={handleSubmit}
                            type={'primary'}
                        >
                            Login
                    </Button>
                    </div>
                    {/* <div className="flex-grow" onClick={() => handleSubmit()}>
                        {window.localStorage.getItem("AUTH") === "staging" ? "Staging" : "Production"}
                    </div> */}
                </div>
            </Box>
        </Container>
    )
}