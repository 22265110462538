import React, { useEffect } from 'react';
import routes from "./routes";
import { BrowserRouter as Router, useRoutes } from "react-router-dom";
import request from './lib/api';

function App() {

  function RoutesRenderer({ routes }) {
    return useRoutes(routes);
  }
  return (
    <Router>
      <RoutesRenderer routes={routes} />
    </Router>
  );
}

export default App;

