import React, { useState, useEffect } from "react";
import moment from "moment"



export default function Clock() {

    const [now, setNow] = useState(moment.now());

    useEffect(() => {
        setInterval(() => {
            let _now = moment.now()
            setNow(_now)
        }, 1000)
    }, [])

    return (
        <div className="text-center">
            <div className="font-semibold text-sm  text-darkGray">
                {moment(now).format("MMM, Do")}
            </div>
            <div className="font-bold text-3xl tracking-wider -mt-1 text-darkGray">
                {moment(now).format("hh:mm")}
            </div>
            <div className="font-bold tracking-wider -mt-1 text-midGray">
                {moment(now).format("YYYY")}
            </div>
        </div>
    )
}