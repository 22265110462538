import request from ".";
import _ from "lodash";
import Users from "../../pages/Users";

export const userApi = {
    login: ({ user_id, password }) => request.post("/auth", {
        user_id,
        password
    }),
    rehydrate: () => request.get("/users/me"),
    filterByName: ({ query }) => request.get("/users/name_filter", { params: { query: _.lowerCase(query) } }),
    get: ({ id }) => request.get(`/users/${id}`),
    changePassword: ({ oldPassword, newPassword }) => request.put('/users/change_password', { old_password: oldPassword, new_password: newPassword }),
    update: ({ id, data }) => request.put(`/users/${id}`, data),
    create: (data) => request.post(`/users`, { ...data }),
    delete: ({ id }) => request.delete(`/users/${id}`)
}