export default {
    0: {
        name: {
            ar: "قيد الانتظار",
            en: "Pending"
        },
        color: "gray"
    },
    1: {
        name: {
            ar: "قيد العمل",
            en: "In progress"
        },
        color: "blue"
    },
    2: {
        name: { 
            ar: "منتهي",
            en: "Done"
        },
        color: "green"
    },
    3: {
        name: { 
            ar: "مسلم",
            en: "Delivered"
        },
        color: "yellow"
    },
}