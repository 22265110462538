
export default {
    0: {
        name: {
            ar: "مباشر",
            en: "Direct"
        },
    },
    1: {
        name: {
            ar: "قاعده اسمنتيه",
            en: "Base Concrete"
        },
    },
    2: {
        name: {
            ar: "قاعده حديديه (جسور)",
            en: "U-Channel"
        },
    },
    3: {
        name: {
            ar: "صندوق أتمتة",
            en: "Automation"
        },
    },
}