export default [
    {
        label: "Installation",
        value: 0
    },
    {
        label: "Splicing",
        value: 1
    },
    {
        label: "Patching",
        value: 2,
    },
    {
        label: "Patching Maintenance",
        value: 3
    },
    {
        label: "Splicing Maintenance",
        value: 4
    },
    {
        label: "Testing",
        value: 5
    },
]