import React, { Component } from 'react';
import XLSX from 'xlsx';
import styled from 'styled-components';
import Button from '../../components/Button';
import _ from "lodash"
import { AiOutlineUpload } from "react-icons/ai";
import SelectInput from '../../components/SelectInput';
import accessDictOptions from '../../lib/data/accessDictOptions';
const DropZone = styled.input`
  opacity: 0;
  height: 100%;
  width: 100%;
  zindex: 0;
  position: absolute;
  left: 0;
  top: 0;
  bottom:0;
  right:0;
  background-color:red;
`;

const make_cols = refstr => {
    let o = [], C = XLSX.utils.decode_range(refstr).e.c + 1;
    for (var i = 0; i < C; ++i) o[i] = { name: XLSX.utils.encode_col(i), key: i }
    return o;
};

const SheetJSFT = [
    "xlsx", "xlsb", "xlsm", "xls", "xml", "csv", "txt", "ods", "fods", "uos", "sylk", "dif", "dbf", "prn", "qpw", "123", "wb*", "wq*", "html", "htm"
].map(function (x) { return "." + x; }).join(",");


interface State {
    file;
    data;
    cols;
    access;
}

interface Props {
    onUpload;
}

class Test extends Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            file: {},
            data: [],
            cols: [],
            access: accessDictOptions[2]
        }
        this.handleFile = this.handleFile.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {
        const files = e.target.files;
        if (files && files[0]) this.setState({ file: files[0] });
    };

    handleFile() {
        /* Boilerplate to set up FileReader */
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;

        reader.onload = (e) => {
            /* Parse data */
            const bstr = e.target.result;
            const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA: true });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_json(ws);
            /* Update state */
            this.setState({ data: data, cols: make_cols(ws['!ref']) }, () => {
                this.props.onUpload(JSON.stringify(this.state.data, null, 2), this.state.access);
                console.log(JSON.stringify(this.state.data, null, 2));
            });
        };

        if (rABS) {
            reader.readAsBinaryString(this.state.file);
        } else {
            reader.readAsArrayBuffer(this.state.file);
        };
    }


    render() {
        return (
            <div className="pb-4">

                <SelectInput
                    name={"unit_access"}
                    label={"Party Access"}
                    value={this.state.access}
                    onSelect={({ target: { value } }) => this.setState({ access: value })}
                    options={accessDictOptions}
                />

                <div
                    className="relative h-32 w-full bg-gray-200 border border-lined border-2">
                    <DropZone type="file" className="form-control" id="file" accept={SheetJSFT} onChange={this.handleChange} />
                    <div
                        style={{
                            zIndex: -1
                        }}
                        className="inset-0 h-full w-full flex flex-col justify-center items-center text-xs text-gray-800">
                        {this.state.file.name ? (
                            <div className="text-xl">
                                {this.state.file ? this.state.file.name : ""}
                            </div>
                        ) : (
                                <AiOutlineUpload size={30} />
                            )}


                    </div>
                </div>


                <Button type='primary'
                    onPress={this.handleFile} >
                    Convert and Upload
                 </Button>
            </div>

        )
    }
}

export default Test;