import React, { useState, useEffect } from "react";
import styled from "styled-components";
import DynamicTable from '@atlaskit/dynamic-table';
import { unitsApi } from "../../lib/api/units";
import moment from "moment";
import unitStatus from "../../lib/data/unitStatus";
import { FiEdit } from "react-icons/fi"
import {
    IoIosRemoveCircleOutline,
    IoMdInformationCircleOutline
} from "react-icons/io"
import TableEdit from "../../components/TableEdit";
import accessDict from "../../lib/data/accessDict";
import { withModals } from "../../lib/HOCs/withModals";
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import TextField from "../../components/TextField";
import { useFormik } from "formik";
import SelectInput from "../../components/SelectInput";
import unitBackendOptions from "../../lib/data/unitBackendOptions";
import accessDictOptions from "../../lib/data/accessDictOptions";
import GenericButton from "@atlaskit/button";
import { userApi } from "../../lib/api/user";
import _ from "lodash";
import userTypeOptions from "../../lib/data/userTypeOptions";
import jobTypeOptions from "../../lib/data/jobTypeOptions";
import { jobsApi } from "../../lib/api/jobs";
import jobType from "../../lib/data/jobType";
import SelectInputAsync from "../../components/SelectInputAsync";
import DateTimeInput from "../../components/DateTimeInput";
import TextArea from "../../components/TextArea";
import jobStatus from "../../lib/data/jobStatus";
import SelectInputMulti from "../../components/SelectInputMulti";
import installationDict from "../../lib/data/installationDict";
import * as yup from "yup";
const Header = styled.div.attrs({ className: "text-xl font-semibold tracking-wide" })``;

const Wrapper = styled.div`
  min-width: 600px;
`;
const CellWrapper = styled.div.attrs({ className: "py-3" })`

`;

const table = [
    {
        title: {
            en: "Job Status",
        },
        accessor: "status",
        wrapper: (value) => (jobStatus[value].name["en"])
    },
    {
        title: {
            en: "Required Date",
        },
        accessor: "required_date",
        wrapper: (value) => (moment(value).format("DD/MM/YYYY"))
    },
    {
        title: {
            en: "Delivery Date",
        },
        accessor: "delivery_date",
        wrapper: (value) => (moment(value).format("DD/MM/YYYY"))
    },
    {
        title: {
            en: "Created",
        },
        accessor: "created_at",
        wrapper: (value) => (moment(value).format("DD/MM/YYYY"))
    },
    {
        title: { en: "Unit Serial", },
        accessor: "unit.serial_number"
    },
    {
        title: { en: "Unit Insallation", },
        accessor: "unit.installation_method",
        wrapper: (value) => (installationDict[value].name.en)
    },
    {
        title: { en: "Unit Status", },
        accessor: "unit.status",
        wrapper: (value) => (unitStatus[value].name["en"])
    },
    {
        title: {
            en: "Worker",
        },
        accessor: "worker_id.name.en",
        wrapper: (value) => (_.capitalize(value))
    },
    {
        title: {
            en: "W_Number",
        },
        accessor: "worker_id.phone",
        wrapper: (value) => (_.capitalize(value))
    },
    {
        title: {
            en: "Creator",
        },
        accessor: "creator_id.name.en",
        wrapper: (value) => (_.capitalize(value))
    },
    {
        title: {
            en: "C_Number",
        },
        accessor: "creator_id.phone",
        wrapper: (value) => (_.capitalize(value))
    },
    {
        title: {
            en: "Access Pool",
        },
        accessor: "job_access",
        wrapper: (value) => (accessDict[value].name.en)
    },
    {
        title: {
            en: "Lat",
        },
        accessor: "unit.location.coordinates.0"
    },
    {
        title: {
            en: "Long",
        },
        accessor: "unit.location.coordinates.1"
    },
    {
        title: {
            en: "Job Type",
        },
        accessor: "job_type",
        wrapper: (value) => (value.map((type, index) => `${jobType[type].name["en"]}${index + 1 !== value.length ? ", " : ""}`))
    },
]

function Jobs(props) {
    const [globalUser] = useState(JSON.parse(window.localStorage.getItem("USER")))
    //Modal Logic
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<any>(null);
    const getData = () => {
        return new Promise(async (resolve, reject) => {
            try {
                setLoading(true);
                let { job } = await jobsApi.get() as any;
                console.log("USERS", job)
                setData(job);
                resolve()
            } catch (e) {
                reject();
                alert("Please Contact Vuedale")
            } finally {
                setLoading(false)
            }
        })
    }

    const onMount = async () => {
        await getData();
    }

    useEffect(() => {
        onMount()
    }, [])


    const loadUsers = (query: string): Promise<Array<any>> => {
        return new Promise(async (resolve, reject) => {
            try {
                let users = await userApi.filterByName({ query }).then(({ user: data }: any) => {
                    return data.map(d => ({ label: d.name, value: d }))
                });
                resolve(users)
            } catch (e) {
                reject(e)
            }
        })
    };



    const loadUnits = (query: string): Promise<Array<any>> => {
        return new Promise(async (resolve, reject) => {
            try {
                let results = await unitsApi.searchBySerial({ query }).then(({ units: data }: any) => {
                    console.log("WHAT THE FUCK MAN", data)
                    return _.map(data, d => ({ label: d.serial_number, value: d }))
                }) as any;
                resolve(results)
            } catch (e) {
                reject(e)
            }
        })
    };

    const updateDataByInjection = (_data: any) => {
        let _copy = [...data];
        let _index = _.findIndex(_copy, (d: any) => { return d._id === _data._id })
        _copy[_index] = _data;
        setData(_copy)
    }

    const updateDataByOmittion = (_data: any) => {
        let _copy = [...data];
        let _index = _.findIndex(_copy, (d: any) => { return d._id === _data._id })
        _copy = [..._copy.slice(0, _index), ..._copy.slice(_index + 1)]
        setData(_copy)
    }

    const updateByDataAddition = (_data: any) => {
        setData([...data, _data]);
    }

    const onDelete = async (deleteData) => {
        try {
            setLoading(true);
            await jobsApi.delete({ id: deleteData._id })
            updateDataByOmittion(deleteData);
            closeForm();
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false);
        }
    }


    const onUpdate = async () => {
        try {
            setLoading(true);
            let { job } = await jobsApi.update({
                id: formData._id,
                data: {
                    description: values.description,
                    job_type: [...values.job_type.map(type => { return type.value })],
                    // job_access: values.job_access.value,
                    unit_id: _.get(values, 'unit.value._id'),
                    worker_id: _.get(values, 'worker_id.value._id'),
                    required_date: moment(values.required_date).format("MM/DD/YYYY")
                },
            }) as any;
            await getData();
            closeForm();
        } catch (e) {
            alert(e)
            console.log(e)
        } finally {
            setLoading(false);
        }
    }




    const onCreate = async () => {
        try {
            setLoading(true);
            if (!moment(values.required_date).isValid()) {
                console.log("HEREHERHERE")
                return setErrors({ "required_date": "Required date is required" })
            }
            let { job } = await jobsApi.create({
                ...values,
                job_type: [...values.job_type.map(type => { return type.value })],
                // job_access: values.job_access.value,
                unit: _.get(values, 'unit.value._id'),
                worker_id: _.get(values, 'worker_id.value._id'),
                required_date: moment(values.required_date).format("MM/DD/YYYY")

            }) as any;
            await getData();
            closeForm();
        } catch (e) {
            alert(e)
            console.log(e)
        } finally {
            setLoading(false)
        }
    }


    const [isDetailsOpen, setDetailsOpen] = useState(false);
    const [detailsData, setDetailsData] = useState<any>(null);
    const closeDetails = () => {
        setDetailsOpen(false);
        setDetailsData(null)
    }

    const openDetails = ({ item }) => {
        setDetailsData(item);
        setDetailsOpen(true);
    }


    const [isDeleteOpen, setDeleteOpen] = useState(false);
    const [deleteData, setDeleteData] = useState<any>(null);
    const closeDelete = () => {
        setDeleteData(null);
        setDeleteOpen(false);
    }
    const openDelete = ({ item }) => {
        setDeleteData(item);
        setDeleteOpen(true);
    }
    const deleteRecord = async (deleteData) => {
        await onDelete(deleteData);
        closeDelete();
    }


    const [isFormOpen, setFormOpen] = useState(false);
    const [formData, setFormData] = useState<any>(null);
    const closeForm = () => {
        setFormOpen(false);
        setFormData(null);
    };
    const openForm = ({ item }) => {
        setFormOpen(true);
        setFormData(item);
        handleChange({ target: { value: { label: item.worker_id.name.en, value: item.worker_id }, name: "worker_id" } });
        handleChange({ target: { value: [...item.job_type.map(e => (jobTypeOptions[e]))], name: "job_type" } });
        handleChange({ target: { value: accessDictOptions[item.job_access], name: "job_access" } });
        handleChange({ target: { value: item.description, name: "description" } });
        handleChange({ target: { value: { label: item.unit.serial_number, value: item }, name: "unit" } });
        handleChange({ target: { value: moment(item.required_date).format("MM-DD-YYYY"), name: "required_date" } });
        setTimeout(() => {
            setErrors({})
        }, 150);
    };

    const openCreateForm = ({ item = null }) => {
        resetForm();
        setFormOpen(true);
        setTimeout(() => {
            setErrors({})
        }, 150);
    }

    const isCreate = () => {
        return !!!formData
    }



    let jobSchema = yup.object().shape({
        unit: yup.object().shape({
            label: yup.string("Please Select Unit").required("Unit is required")
        }),
        worker_id: yup.object().shape({
            label: yup.string("Please Select Worker").required("Worker is required")
        }),
    });

    const { handleChange, values, errors, handleSubmit, resetForm, setErrors } = useFormik({
        initialValues: {
            unit: {},
            worker_id: {},
            job_type: [jobTypeOptions[0]],
            description: " ",
            required_date: moment.now(),
        },
        validateOnBlur: false,
        validationSchema: jobSchema,
        onSubmit: !isCreate() ? onUpdate : onCreate
    })


    //End of Modal Logic

    useEffect(() => { console.log("FORM", values) }, [values])





    const head = {
        cells: [
            {
                isSortable: true,
                shouldTruncate: false,
                width: 50,
                key: "unit_serial",
                content: "Unit Serial",
            },
            {
                isSortable: true,
                shouldTruncate: false,
                width: 50,
                key: "Status",
                content: "Status",
            },
            {
                isSortable: true,
                shouldTruncate: false,
                width: 50,
                key: "supervisor",
                content: "Creator",
            },
            {
                isSortable: true,
                shouldTruncate: false,
                width: 50,
                key: "worker_id",
                content: "Worker",
            },
            {
                isSortable: true,
                shouldTruncate: false,
                width: 50,
                key: "delivery_date",
                content: "Delivery Serial",
            },
            {
                isSortable: true,
                shouldTruncate: false,
                width: 50,
                key: "required_date",
                content: "Required Date",
            },
            {
                isSortable: true,
                shouldTruncate: false,
                width: 50,
                key: "user_access",
                content: "Access Pool",
            },
            {
                isSortable: false,
                shouldTruncate: false,
                width: 50,
                key: "Edit",
                content: "Edit",
            },
        ],
        key: "Hello Wrold"
    }


    const TransformData = () => {
        return [
            ...data.map((r, i) => ({
                key: `row-${i}-${r._id}`,
                cells: [
                    {
                        sortKey: r.unit.serial_number,
                        key: `${(r.unit.serial_number)}${r._id}`,
                        content: (<CellWrapper><div className="uppercase tracking-wider">{`${_.capitalize(r.unit.serial_number)}`}</div></CellWrapper>)
                    },
                    {
                        sortKey: r.status,
                        key: `${JSON.stringify(r.status)}${r.id}`,
                        content: (<CellWrapper><div className="" style={{ color: jobStatus[r.status].color }}>{jobStatus[r.status].name.en}</div></CellWrapper>)
                    },
                    {
                        sortKey: r.creator_id.name.en,
                        key: `${JSON.stringify(r.creator_id.name.en)}${r._id}`,
                        content: (<CellWrapper>{<div className="capitalize">{r.creator_id.name.en}</div>}</CellWrapper>),
                    },
                    {
                        sortKey: r.worker_id.name.en,
                        key: `${JSON.stringify(r.worker_id.name.en)}${r._id}`,
                        content: (<CellWrapper>{r.worker_id.name.en}</CellWrapper>),
                    },
                    {
                        sortKey: moment(r.delivery_date).format('x'),
                        key: `${JSON.stringify(r.delivery_date)}${r.id}`,
                        content: (<CellWrapper>{moment(r.delivery_date).format("MMM DD, YYYY")}</CellWrapper>)
                    },
                    {
                        sortKey: moment(r.required_date).format('x'),
                        key: `${JSON.stringify(r.required_date)}${r.id}`,
                        content: (<CellWrapper>{moment(r.required_date).format("MMM DD, YYYY")}</CellWrapper>)
                    },
                    {
                        sortKey: r.job_access,
                        key: `${r.job_access}${r._id}`,
                        content: (<CellWrapper><div className="flex"><div className="px-2 text-sm text-white rounded-md font-semibold" style={{ backgroundColor: accessDict[r.job_access].color, color: accessDict[r.job_access].text }}>{accessDict[r.job_access].name.en}</div></div></CellWrapper>),
                    },

                    {
                        key: `${r._id}${"EDIT"}`,
                        content: (
                            <div className="flex  items-center">
                                <div
                                    onClick={() => openDetails({ item: r })}
                                    className="mr-3 cursor-pointer hover:opacity-50">
                                    <IoMdInformationCircleOutline size={25} />
                                </div>
                                <div>
                                    <TableEdit
                                        openEdit={() => openForm({ item: r })}
                                        openDelete={() => openDelete({ item: r })}
                                    />
                                </div>
                            </div>)
                    },
                ]
            }))
        ]
    }

    return (
        <div>
            <div className="flex items-center border-b justify-between w-full pb-4">
                <Header>
                    Jobs
            </Header>
                <GenericButton
                    onClick={() => openCreateForm({ item: null })}
                    appearance="primary" spacing="compact">
                    <div className="text-sm">
                        Create
                    </div>
                </GenericButton>
            </div>
            <Wrapper>
                <DynamicTable
                    // caption={"Unit"}
                    head={head}
                    rows={data && data.length > 0 ? TransformData() : []}
                    rowsPerPage={10}
                    defaultPage={1}
                    loadingSpinnerSize="large"
                    isLoading={loading}
                    isFixedSize
                    defaultSortKey="term"
                    defaultSortOrder="ASC"
                    onSort={() => console.log('onSort')}
                    onSetPage={() => console.log('onSetPage')}
                />
            </Wrapper>
            <ModalTransition>
                {isDeleteOpen && (
                    <Modal
                        actions={[
                            { text: 'Delete', onClick: () => deleteRecord(deleteData) },
                            { text: 'No, keep it', onClick: closeDelete },
                        ]}
                        onClose={closeDelete}
                        heading={`You're about to delete the job for unit ${_.get(deleteData, 'unit.serial_number')}`}
                        appearance="danger"
                    >
                        <p>
                            Before you delete it permanently, there’s some things you should
                            know:
                            </p>
                        <ul className="pt-1">
                            <li> - This action is not reversable.</li>
                            <li> - You can always update the record rather than delete it.</li>
                        </ul>
                    </Modal>
                )}
            </ModalTransition>
            <ModalTransition>
                {isDetailsOpen && (
                    <Modal
                        actions={[
                            { text: 'Dismiss', onClick: closeDetails },
                        ]}
                        onClose={closeDetails}
                        heading={`Job Details`}
                    >
                        {table.map(r => {
                            return (
                                <div className="flex items-center py-2 mb-1 border-b justify-between">
                                    <div className="text-primaryBlack uppercase text-sm">
                                        {r.title.en}
                                    </div>
                                    <div className="text-primaryBlack">
                                        {r.wrapper ? (
                                            <div>
                                                {r.wrapper(_.get(detailsData, r.accessor))}
                                            </div>
                                        ) : (
                                                _.get(detailsData, r.accessor)
                                            )}
                                    </div>
                                </div>
                            )
                        })}
                    </Modal>
                )}
            </ModalTransition>
            <ModalTransition>
                {isFormOpen && (
                    <Modal
                        actions={[
                            { text: `${isCreate() ? "Create" : "Update"}`, onClick: () => handleSubmit() },
                            { text: 'Dismiss', onClick: closeForm },
                        ]}
                        onClose={closeForm}
                        heading={`${isCreate() ? "Creating Job" : "Updating Job"}`}
                    >
                        <SelectInputMulti
                            name={"job_type"}
                            label={"Job Type"}
                            value={values.job_type}
                            onSelect={handleChange}
                            options={jobTypeOptions}
                            error={errors.job_type}
                        />
                        <SelectInputAsync
                            onSelect={handleChange}
                            name="unit"
                            label={"Unit"}
                            value={values.unit}
                            optionLoader={loadUnits}
                            error={_.get(errors, "unit.label")}
                        />
                        <SelectInputAsync
                            onSelect={handleChange}
                            name="worker_id"
                            label={"Worker"}
                            value={values.worker_id}
                            optionLoader={loadUsers}
                            error={_.get(errors, "worker_id.label")}
                        />
                        <DateTimeInput
                            name="required_date"
                            value={values.required_date}
                            onChange={handleChange}
                            label={"Required Date"}
                            error={_.get(errors, "required_date")}
                        />
                        <TextArea
                            label={"Description"}
                            value={values.description}
                            onChange={handleChange}
                            name={"description"}
                        />

                    </Modal>
                )}
            </ModalTransition>
        </div>
    )
}




export default (Jobs)