import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import DynamicTable from '@atlaskit/dynamic-table';
import { unitsApi } from "../../lib/api/units";
import moment from "moment";
import unitStatus from "../../lib/data/unitStatus";
import { FiEdit, FiSearch } from "react-icons/fi"
import {
    IoIosRemoveCircleOutline
} from "react-icons/io"
import TableEdit from "../../components/TableEdit";
import accessDict from "../../lib/data/accessDict";
import { withModals } from "../../lib/HOCs/withModals";
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import TextField from "../../components/TextField";
import { useFormik } from "formik";
import SelectInput from "../../components/SelectInput";
import unitBackendOptions from "../../lib/data/unitBackendOptions";
import accessDictOptions from "../../lib/data/accessDictOptions";
import GenericButton from "@atlaskit/button";
import { update } from "lodash";
import _ from "lodash";
import unitTypesOptions from "../../lib/data/unitTypesOptions";
import TextArea from "../../components/TextArea";
import AdditionSelect from "../../components/AdditionSelect";
import unitTypes from "../../lib/data/unitTypes"
import installationMethodDictionary from "../../lib/data/installationMethodDictionary";
import installationDict from "../../lib/data/installationDict";
import Test from "../Test";
import * as yup from "yup";
import Input from "react-select/src/components/Input";
import Colors from "../../lib/constants/Colors";

const Header = styled.div.attrs({ className: "text-xl font-semibold tracking-wide flex items-center" })``;

const Wrapper = styled.div`
  min-width: 600px;
`;
const CellWrapper = styled.div.attrs({ className: "py-3" })``;



function Units(props) {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<any>(null);
    const getData = async (query = null) => {
        try {
            setLoading(true);
            let { units: u } = await unitsApi.get({ query }) as any;
            setData(u);
        } catch (e) {
            alert("Please Contact Admin")
        } finally {
            setLoading(false)
        }
    }

    // useEffect(() => {
    //     getData()
    // }, [])


    //Modal Logic

    const transformUnitTypeOptions = (options) => {
        let arr = []
        options.map(o => {
            for (var i = 0; i < o.quantity; i++) {
                arr = [...arr, unitTypesOptions[unitTypes[o.type].index]]
            }
        })
        return arr;
    }

    const convertUnitTypesOptions = (options) => {
        return _.map(_.groupBy(options, (o) => o.value), no => ({ type: no[0].value, quantity: no.length }));
    }

    const updateDataByInjection = (_data: any) => {
        let _copy = [...data];
        let _index = _.findIndex(_copy, (d: any) => { return d._id === _data._id })
        _copy[_index] = _data;
        setData(_copy)
    }

    const updateDataByOmittion = (_data: any) => {
        let _copy = [...data];
        let _index = _.findIndex(_copy, (d: any) => { return d._id === _data._id })
        _copy = [..._copy.slice(0, _index), ..._copy.slice(_index + 1)]
        setData(_copy)
    }

    const updateByDataAddition = (_data: any) => {
        setData([...data, _data]);
    }
    const onDelete = async (deleteData) => {
        try {
            setLoading(true);
            await unitsApi.delete({ id: deleteData._id })
            updateDataByOmittion(deleteData);
            closeForm();
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false);
        }
    }
    const onUpdate = async () => {
        try {
            setLoading(true);
            console.log("FORM DATA", formData)
            let { unit } = await unitsApi.update({
                id: formData._id, data: {
                    ...values,
                    status: values.status.value,
                    unit_access:
                        values.unit_access.value,
                    location: {
                        type: "Point",
                        coordinates: [
                            values.location.lat,
                            values.location.lng
                        ]
                    },
                    unit_type: convertUnitTypesOptions(values.unit_type),
                    info: {},
                    installation_method: values.installation_method.value
                }
            }) as any;
            updateDataByInjection(unit);
            closeForm();
        } catch (e) {
            alert(e);
            console.log(e)
        } finally {
            setLoading(false);
        }
    }

    const onCreate = async () => {
        try {
            setLoading(true);
            console.log("FORM DATA", formData)
            let { unit } = await unitsApi.create(
                {
                    ...values,
                    status: values.status.value,
                    unit_access:
                        values.unit_access.value,
                    location: {
                        type: "Point",
                        coordinates: [
                            values.location.lat,
                            values.location.lng
                        ]
                    },
                    unit_type: convertUnitTypesOptions(values.unit_type),
                    info: {},
                    installation_method: values.installation_method.value
                }
            ) as any;
            updateByDataAddition(unit)
            closeForm();
        } catch (e) {
            alert(e);
            console.log(e)
        } finally {
            setLoading(false);
        }
    }



    const [isUploadOpen, setUploadOpen] = useState(false);
    const [uploadData, setUploadData] = useState(null);
    const closeUpload = () => {
        setUploadOpen(false);
        setUploadData(null);
    }
    const openUpload = () => {
        setUploadOpen(true);
    }

    const onUpload = (data, bulk_access) => {
        // setUploadData(data)
        uploadUnitsFromExcel(data, bulk_access);
    }

    const resetUpload = () => {
        setUploadOpen(null);
    }

    const convertData = () => {

    }

    const uploadUnitsFromExcel = async (_data, bulk_access) => {
        console.log("HERE", _data)
        try {
            setLoading(true)
            const transformType = (unit_type) => {
                let items = unit_type.split(",");
                let arr = [];
                items.map(w => {
                    let type = parseInt(w.split("*")[0]);
                    let quantity = parseInt(w.split("*")[1])
                    arr = [...arr, { type, quantity }]
                })

                return arr;
            }
            let uploadDATA = JSON.parse(_data).map(r => ({
                status: r.status,
                location: {
                    type: "Point",
                    coordinates: [
                        r.location.split(" ")[0],
                        r.location.split(" ")[1],
                    ]
                },
                unit_type: r.unit_types ? transformType(r.unit_types) : [{ type: 24, quantity: 1 }],
                info: {},
                installation_method: r.installation_method,
                serial_number: r.serial_number,
                slug: r.slug,
                unit_access: bulk_access ? bulk_access.value : 2,
                description: r.description ? r.description : " "
            }))
            console.log("DATA", JSON.stringify(uploadDATA))
            await unitsApi.bulkCreate([
                ...uploadDATA
            ]);
            setLoading(false)
            window.location.reload();
        } catch (e) {
            console.log(e)
            closeUpload()
            alert(JSON.stringify(e))
        } finally {
            setLoading(false)
        }
    }


    const [isDeleteOpen, setDeleteOpen] = useState(false);
    const [deleteData, setDeleteData] = useState<any>(null);
    const closeDelete = () => {
        setDeleteData(null);
        setDeleteOpen(false);
    }
    const openDelete = ({ item }) => {
        setDeleteData(item);
        setDeleteOpen(true);
    }
    const deleteRecord = async (deleteData) => {
        await onDelete(deleteData);
        closeDelete();
    }


    const [isFormOpen, setFormOpen] = useState(false);
    const [formData, setFormData] = useState<any>(null);
    const closeForm = () => {
        setFormOpen(false);
        setFormData(null);
    };
    const openForm = ({ item }) => {
        setFormOpen(true);
        setFormData(item);
        handleChange({ target: { value: item.serial_number, name: "serial_number" } });
        handleChange({ target: { value: unitBackendOptions[item.status], name: "status" } });
        handleChange({ target: { value: accessDictOptions[item.unit_access], name: "unit_access" } });
        handleChange({ target: { value: item.location.coordinates[0], name: "location.lat" } });
        handleChange({ target: { value: item.location.coordinates[1], name: "location.lng" } });
        handleChange({ target: { value: item.slug, name: "slug" } });
        handleChange({ target: { value: item.description, name: "description" } });
        handleChange({ target: { value: transformUnitTypeOptions(item.unit_type), name: "unit_type" } });
        setTimeout(() => {
            setErrors({})
        }, 120);
    };

    const openCreateForm = ({ item = null }) => {
        resetForm();
        setFormOpen(true);
        setTimeout(() => {
            setErrors({})
        }, 120);
    }


    let UnitSchema = yup.object().shape({
        location: yup.object().shape({
            lat: yup.string("Please enter a valid latitude").required("Please enter unit latitude"),
            lng: yup.string("Please enter a valid longtitude").required("Please enter unit longtitude"),
        }),
        serial_number: yup.string("Please enter a valid serial number").required("Serial number is required"),
        slug: yup.string("Enter a valid slug").required("Unit's slug is required"),
    });


    const { handleChange, values, errors, handleSubmit, resetForm, setErrors } = useFormik({
        initialValues: {
            serial_number: "",
            slug: "",
            status: unitBackendOptions[0],
            unit_access: accessDictOptions[0],
            location: {
                lat: "",
                lng: ""
            },
            description: "",
            unit_type: [unitTypesOptions[0]],
            installation_method: installationMethodDictionary[0]
        },
        validateOnBlur: false,
        validationSchema: UnitSchema,
        onSubmit: !!formData ? onUpdate : onCreate
    })


    useEffect(() => {
        console.log("FORM", values)
    }, [values])


    //End of Modal Logic

    const isCreate = () => {
        return !!!formData
    }

    useEffect(() => {
        console.log("DELETE DATA", deleteData)
        console.log("FORM DATA", formData)
    }, [formData, deleteData])



    const head = {
        cells: [
            {
                isSortable: true,
                shouldTruncate: false,
                width: 50,
                key: "serial_number",
                content: "Serial_number",
            },
            {
                isSortable: true,
                shouldTruncate: false,
                width: 50,
                key: "installation_method",
                content: "Installation Method",
            },
            {
                isSortable: true,
                shouldTruncate: false,
                width: 100,
                key: "slug",
                content: "Slug",
            },
            {
                isSortable: true,
                shouldTruncate: false,
                width: 50,
                key: "summation",
                content: "Port Total",
            },
            {
                isSortable: true,
                shouldTruncate: false,
                width: 50,
                key: "status",
                content: "Status",
            },
            {
                isSortable: true,
                shouldTruncate: false,
                width: 50,
                key: "created_at",
                content: "Created at",
            },
            {
                isSortable: false,
                shouldTruncate: false,
                width: 50,
                key: "location",
                content: "Location",
            },
            {
                isSortable: true,
                shouldTruncate: false,
                width: 50,
                key: "access",
                content: "Access Pool",
            },
            {
                isSortable: false,
                shouldTruncate: false,
                width: 50,
                key: "Edit",
                content: "Edit",
            },
        ],
        key: "Hello Wrold"
    }


    const TransformData = () => {
        return [
            ...data.map((r, i) => {
                let portTotalsum = 0;
                if (r.unit_type.length === 0) {
                } else {
                    r.unit_type.map(o => portTotalsum = portTotalsum + o.type * o.quantity)
                }
                return ({
                    key: `row-${i}-${r._id}`,
                    cells: [
                        {
                            sortKey: r.serial_number,
                            key: `${r.id}${JSON.stringify(r.serial_number)}`,
                            content: r.serial_number
                        },
                        {
                            sortKey: r.installation_method,
                            key: `${r.id}${JSON.stringify(r.installation_method)}`,
                            content: installationDict[r.installation_method].name["en"]
                        },
                        {
                            sortKey: r.slug,
                            key: `${r.id}${JSON.stringify(r.slug)}`,
                            content: (<CellWrapper><div className="flex"><div className="bg-gray-600 w-auto text-center text-left px-2 rounded-md text-white">{`#${r.slug.length > 27 ? r.slug.slice(0, 30) + "..." : r.slug}`}</div></div></CellWrapper>)
                        },
                        {
                            sortKey: portTotalsum,
                            key: `${r._id}${JSON.stringify(portTotalsum)}`,
                            content: (<CellWrapper><div className=" font-semibold ">{portTotalsum}</div></CellWrapper>)
                        },
                        {
                            sortKey: r.status,
                            key: `${r.id}${JSON.stringify(r.status)}`,
                            content: (<CellWrapper><div className=" capitalize " style={{ color: unitStatus[r.status].textColor }}>{unitStatus[r.status].name["en"]}</div></CellWrapper>)
                        },
                        {
                            sortKey: moment(r.created_at).format('x'),
                            key: `${r.id}${JSON.stringify(r.created_at)}`,
                            content: (<CellWrapper>{moment(r.created_at).format("MMM DD, YYYY")}</CellWrapper>)
                        },
                        {
                            key: `${r.id}${JSON.stringify(r.location)}`,
                            content: (<CellWrapper><div className="link hover:underline flex items-center">Go To Map</div></CellWrapper>),
                            onClick: () => { window.open(`https://www.google.com/maps/search/?api=1&query=${r.location.coordinates[0]},${r.location.coordinates[1]}`) }
                        },
                        {
                            sortKey: r.unit_access,
                            key: `${r.id}${JSON.stringify(r.unit_access)}`,
                            content: (<CellWrapper><div className="flex"><div className="px-2 text-sm text-white rounded-md font-semibold" style={{ backgroundColor: accessDict[r.unit_access].color, color: accessDict[r.unit_access].text }}>{accessDict[r.unit_access].name.en}</div></div></CellWrapper>),
                        },
                        {
                            key: `${r.id}${"EDIT"}`,
                            content: (
                                <TableEdit
                                    openEdit={() => openForm({ item: r })}
                                    openDelete={() => openDelete({ item: r })}
                                />
                            )
                        }
                    ]
                })
            })
        ]
    }

    const [searchQuery, setSearchQuery] = useState<any>();
    const filterbySearch = useRef(_.throttle(async (query: any) => {
        await getData(query)
    }, 1250, { trailing: true, leading: false }))

    useEffect(() => {
        setLoading(true)
        filterbySearch.current(searchQuery)
    }, [searchQuery])


    return (
        <div>
            <div className="flex items-center border-b justify-between w-full pb-4">
                <Header>
                    Units
                    <div className="ml-4 flex items-center bg-gray-100 shadow px-2 rounded-md overflow-hidden">
                        <FiSearch size={20} color={Colors.$primaryBlack} />
                        <input
                            placeholder={"Search By Serial"}
                            type={"text"}
                            name={"query"}
                            className=" bg-gray-100 p-1 px-2 text-sm text-gray-900"
                            value={searchQuery}
                            onChange={({ target: { value } }) => { setSearchQuery(value) }}
                        />
                    </div>
                </Header>
                <div className="flex">
                    <GenericButton
                        onClick={() => openUpload()}
                        appearance="warning" spacing="compact">
                        <div className="text-sm">
                            Upload
                    </div>
                    </GenericButton>
                    <div className="ml-3">
                        <GenericButton
                            onClick={() => openCreateForm({ item: null })}
                            appearance="primary" spacing="compact">
                            <div className="text-sm">
                                Create
                    </div>
                        </GenericButton>
                    </div>
                </div>
            </div>
            <Wrapper>
                <DynamicTable
                    // caption={"Unit"}
                    head={head}
                    rows={data ? TransformData() : []}
                    rowsPerPage={10}
                    defaultPage={1}
                    loadingSpinnerSize="large"
                    isLoading={loading}
                    isFixedSize
                    defaultSortKey="term"
                    defaultSortOrder="ASC"
                    onSort={() => console.log('onSort')}
                    onSetPage={() => console.log('onSetPage')}
                />
            </Wrapper>
            <ModalTransition>
                {isUploadOpen && (
                    <Modal
                        onClose={closeUpload}
                        heading={`Bulk Excel Upload`}
                    // appearance="danger"
                    >
                        <div className="mb-3 text-sm">
                            Drag and drop files into the frame, or click on it to select from files,
                            Once you're ready click on convert and upload to start.
                        </div>
                        <Test onUpload={onUpload} />
                        <div>

                        </div>
                    </Modal>
                )}
            </ModalTransition>
            <ModalTransition>
                {isDeleteOpen && (
                    <Modal
                        actions={[
                            { text: 'Delete', onClick: () => deleteRecord(deleteData) },
                            { text: 'No, keep it', onClick: closeDelete },
                        ]}
                        onClose={closeDelete}
                        heading={`You're about to delete unit ${_.get(deleteData, 'serial_number')}`}
                        appearance="danger"
                    >
                        <p>
                            Before you delete it permanently, there’s some things you should
                            know:
                            </p>
                        <ul className="pt-1">
                            <li> - This action is not reversable.</li>
                            <li> - You cannot delete a unit that is assigned to a job.</li>
                            <li> - You can always update the record rather than delete it.</li>
                        </ul>
                    </Modal>
                )}
            </ModalTransition>
            <ModalTransition>
                {isFormOpen && (
                    <Modal
                        actions={[
                            { text: `${isCreate() ? "Create" : "Update"}`, onClick: () => handleSubmit() },
                            { text: 'Dismiss', onClick: closeForm },
                        ]}
                        onClose={closeForm}
                        heading={`${isCreate() ? "Creating Unit" : "Updating Unit"}`}
                    >
                        <AdditionSelect
                            name={"unit_type"}
                            label={"Unit Type"}
                            value={values.unit_type}
                            onSelect={handleChange}
                            options={unitTypesOptions}
                        />
                        <SelectInput
                            name={"installation_method"}
                            label={"Installation method"}
                            value={values.installation_method}
                            onSelect={handleChange}
                            options={installationMethodDictionary}
                        />
                        <SelectInput
                            name={"status"}
                            label={"Status"}
                            value={values.status}
                            onSelect={handleChange}
                            options={unitBackendOptions}
                        />

                        {/* <SelectInput
                            name={"unit_type.type"}
                            label={"Unit Type"}
                            value={values.unit_type.type}
                            onSelect={handleChange}
                            options={unitTypesOptions}
                        />
                        <TextField
                            label={"Box Quantity"}
                            value={values.unit_type.quantity}
                            onChange={handleChange}
                            name={"unit_type.quantity"}
                        /> */}
                        <SelectInput
                            name={"unit_access"}
                            label={"Party Access"}
                            value={values.unit_access}
                            onSelect={isCreate() ? handleChange : () => { }}
                            options={accessDictOptions}
                        />

                        <TextField
                            label={"Serial Number"}
                            value={values.serial_number}
                            onChange={handleChange}
                            name={"serial_number"}
                            error={errors.serial_number}
                        />

                        <TextField
                            label={"Arabic Slug"}
                            value={values.slug}
                            onChange={handleChange}
                            name={"slug"}
                            error={errors.slug}
                        />


                        <TextField
                            label={"Latitude"}
                            value={values.location.lat}
                            onChange={handleChange}
                            name={"location.lat"}
                            error={_.get(errors, "location.lat")}
                        />

                        <TextField
                            label={"Longitude"}
                            value={values.location.lng}
                            onChange={handleChange}
                            name={"location.lng"}
                            error={_.get(errors, "location.lng")}
                        />
                        <TextArea
                            label={"Description"}
                            value={values.description}
                            onChange={handleChange}
                            name={"description"}
                        />

                    </Modal>
                )}
            </ModalTransition>
        </div>
    )
}




export default (Units)