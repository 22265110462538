
export default [
    {
        label: "Direct",
        value: 0
    },
    {
        label: "Base Concrete",
        value: 1
    },
    {
        label: "U-Channel",
        value: 2,
    },
    {
        label: "Automation",
        value: 3
    },
]