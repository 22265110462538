import Colors from "../constants/Colors";


export default [
    {
        value: 0,
        label: "Inactive",
        color: "red",
        textColor: "red",
    },
    {
        value: 1,
        label: "Active",
        color: "green",
        textColor: "green",
    },
    {
        value: 2,
        label: "Work Required",
        color: Colors.$primaryOrange,
        textColor: Colors.$primaryOrange,
    },
    {
        value: 3,
        label: "Not Installed",
        color: "transparent",
        textColor: Colors.$darkGray,
    },
    {
        value: 4,
        label: "Job Assignd",
        color: "blue",
        isDisabled: true,
        textColor: "blue",
    },
    {
        value: 5,
        label: "Out of service",
        color: "black",
        textColor: "black",
    },
]