import React, { useState, useEffect } from "react";
import styled from "styled-components";
import DynamicTable from '@atlaskit/dynamic-table';
import { unitsApi } from "../../lib/api/units";
import moment from "moment";
import unitStatus from "../../lib/data/unitStatus";
import { FiEdit } from "react-icons/fi"
import {
    IoIosRemoveCircleOutline
} from "react-icons/io"
import TableEdit from "../../components/TableEdit";
import accessDict from "../../lib/data/accessDict";
import { withModals } from "../../lib/HOCs/withModals";
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import TextField from "../../components/TextField";
import { useFormik } from "formik";
import SelectInput from "../../components/SelectInput";
import unitBackendOptions from "../../lib/data/unitBackendOptions";
import accessDictOptions from "../../lib/data/accessDictOptions";
import GenericButton from "@atlaskit/button";
import { update } from "lodash";
import { userApi } from "../../lib/api/user";
import userType from "../../lib/data/userType";
import _ from "lodash";

import userTypeOptions from "../../lib/data/userTypeOptions";
import metatypes from "../../lib/data/metatypes";
import * as yup from "yup"
const Header = styled.div.attrs({ className: "text-xl font-semibold tracking-wide" })``;

const Wrapper = styled.div`
  min-width: 600px;
`;
const CellWrapper = styled.div.attrs({ className: "py-3" })`

`;

const PAGE_SIZE = 10;

function Users(props) {
    const [globalUser] = useState(JSON.parse(window.localStorage.getItem("USER")))
    //Modal Logic
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<any>(null);
    const [count, setCount] = useState();
    const getData = () => {
        return new Promise(async (resolve, reject) => {
            try {
                setLoading(true);
                let { user: _users, count: _count } = await userApi.get({ id: '' }) as any;
                console.log("USERS", _users)
                setData(_users);
                setCount(_count);
                resolve()
            } catch (e) {
                reject();
                alert("Please Contact Admin")
            } finally {
                setLoading(false)
            }
        })
    }

    const onMount = async () => {
        await getData();
    }

    useEffect(() => {
        onMount()
    }, [])




    const updateDataByInjection = (_data: any) => {
        let _copy = [...data];
        let _index = _.findIndex(_copy, (d: any) => { return d._id === _data._id })
        _copy[_index] = _data;
        setData(_copy)
    }

    const updateDataByOmittion = (_data: any) => {
        let _copy = [...data];
        let _index = _.findIndex(_copy, (d: any) => { return d._id === _data._id })
        _copy = [..._copy.slice(0, _index), ..._copy.slice(_index + 1)]
        setData(_copy)
    }

    const updateByDataAddition = (_data: any) => {
        setData([...data, _data]);
    }

    const onDelete = async (deleteData) => {
        try {
            setLoading(true);
            await userApi.delete({ id: deleteData._id })
            updateDataByOmittion(deleteData);
            closeForm();
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false);
        }
    }


    const onUpdate = async () => {
        try {
            setLoading(true);
            let { user } = await userApi.update({
                id: formData._id,
                data: {
                    ...values,
                    meta_type: values.meta_type.value,
                    type: values.type.value,
                    user_access: 0
                },
            }) as any;
            updateDataByInjection(user);
            // await getData();
            closeForm();
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false);
        }
    }




    const onCreate = async () => {
        try {
            setLoading(true);
            let { user } = await userApi.create({
                ...values,
                meta_type: values.meta_type.value,
                type: values.type.value,
                user_access: values.user_access.value,
                password: "12345",
            }) as any;
            updateByDataAddition(user);
            closeForm();
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false)
        }
    }





    const [isDeleteOpen, setDeleteOpen] = useState(false);
    const [deleteData, setDeleteData] = useState<any>(null);
    const closeDelete = () => {
        setDeleteData(null);
        setDeleteOpen(false);
    }
    const openDelete = ({ item }) => {
        setDeleteData(item);
        setDeleteOpen(true);
    }
    const deleteRecord = async (deleteData) => {
        await onDelete(deleteData);
        closeDelete();
    }


    const [isFormOpen, setFormOpen] = useState(false);
    const [formData, setFormData] = useState<any>(null);
    const closeForm = () => {
        setFormOpen(false);
        setFormData(null);
    };
    const openForm = ({ item }) => {
        setFormOpen(true);
        setFormData(item);
        handleChange({ target: { value: item.name.ar, name: "name.ar" } });
        handleChange({ target: { value: item.name.en, name: "name.en" } });
        handleChange({ target: { value: userTypeOptions[item.type], name: "type" } });
        handleChange({ target: { value: accessDictOptions[item.user_access], name: "user_access" } });
        handleChange({ target: { value: item.phone, name: "phone" } });
        handleChange({ target: { value: item.email, name: "email" } });
        handleChange({ target: { value: item.user_id, name: "user_id" } });

        setTimeout(() => {
            setErrors({});
        }, 100);

    };

    const openCreateForm = ({ item = null }) => {
        resetForm();
        setFormOpen(true);
    }

    const isCreate = () => {
        return !!!formData
    }


    let UserSchema = yup.object().shape({
        name: yup.object().shape({
            ar: yup.string().required("User's name in the arabic langauge is required").min(3, "Name must be longer than 3 characters").max(50, "Name must be shorter than 50 characters"),
            en: yup.string().required("Please enter the user's name in the english language").min(3, "Name must be longer than 3 characters").max(50, "Name must be shorter than 50 characters"),
        }),
        phone: yup.number("Please enter a valid phone number").required("Phone number is required").positive().integer(),
        email: yup.string().email("Enter a valid email address").required("Please enter the user's email address"),
        user_id: yup.string("Please enter employee ID").required("Please enter employee ID").min(1,"Employee ID must be atleast 1 number").max(5,"Employee ID must be at most 5 numbers"),
    });

    const [statefulMetaTypes, setMetatypes] = useState(metatypes);

    const { handleChange, values, errors, handleSubmit, resetForm, setErrors } = useFormik({
        initialValues: {
            name: {
                en: "",
                ar: ""
            },
            email: "",
            type: userTypeOptions[0],
            user_access: accessDictOptions[0],
            phone: "",
            meta_type: statefulMetaTypes[0],
            user_id: ""
        },
        validateOnBlur: false,
        validationSchema: UserSchema,
        onSubmit: !isCreate() ? onUpdate : onCreate
    })
    useEffect(() => {

        if (values.type.value == 0 || values.type.value == 2) {
            setMetatypes([metatypes[0]])
        }
        if (values.type.value == 1) {
            setMetatypes([metatypes[0], metatypes[2], metatypes[3]])
        }
    }, [values])

    useEffect(() => {
        handleChange({ target: { value: metatypes[0], name: "meta_type" } })
    }, [values.type])





    useEffect(() => {
        console.log("DELETE DATA", deleteData)
        console.log("FORM DATA", formData)
    }, [formData, deleteData])








    const head = {
        cells: [
            {
                isSortable: true,
                shouldTruncate: false,
                width: 25,
                key: "empoloyee_id",
                content: "Employee ID",
            },
            {
                isSortable: true,
                shouldTruncate: false,
                width: 50,
                key: "name",
                content: "Name",
            },
            {
                isSortable: true,
                shouldTruncate: false,
                width: 50,
                key: "phone",
                content: "Phone Number",
            },
            {
                isSortable: true,
                shouldTruncate: false,
                width: 100,
                key: "email",
                content: "Email",
            },
            {
                isSortable: true,
                shouldTruncate: false,
                width: 50,
                key: "type",
                content: "Type",
            },
            {
                isSortable: true,
                shouldTruncate: false,
                width: 50,
                key: "user_access",
                content: "Access Pool",
            },

            {
                isSortable: true,
                shouldTruncate: false,
                width: 50,
                key: "created_at",
                content: "Created At",
            },
            {
                isSortable: false,
                shouldTruncate: false,
                width: 50,
                key: "Edit",
                content: "Edit",
            },
        ],
        key: "Hello Wrold"
    }



    const TransformData = () => {
        return [
            ...data.map((r, i) => ({
                key: `row-${i}-${r._id}`,
                cells: [
                    {
                        sortKey: r.user_id,
                        isSortable: true,
                        key: r.user_id,
                        content: <CellWrapper> <div className="">{r.user_id}</div></CellWrapper>
                    },
                    {
                        sortKey: r.name.en,
                        key: `${(r.name)}${r._id}`,
                        content: (<CellWrapper>{`${_.capitalize(r.name.en)}`}</CellWrapper>)
                    },
                    {
                        sortKey: r.phone,
                        key: `${JSON.stringify(r.phone)}${r._id}`,
                        content: (<CellWrapper>{r.phone}</CellWrapper>)
                    },
                    {
                        sortKey: r.email,
                        key: `${JSON.stringify(r.email)}${r._id}`,
                        content: (<CellWrapper>{r.email}</CellWrapper>),
                    },
                    {
                        sortKey: r.meta_type !== 0 ? r.meta_type : r.type,
                        isSortable: true,
                        key: `${(r.type)}${r._id}${r.meta_type}`,
                        content: (<CellWrapper>{r.meta_type !== 0 ? metatypes[r.meta_type].label : userType[r.type].name.en}</CellWrapper>),
                    },
                    {
                        sortKey: r.user_access,
                        key: `${r.user_access}${r._id}`,
                        content: (<CellWrapper><div className="flex"><div className="px-2 text-sm text-white rounded-md font-semibold" style={{ backgroundColor: accessDict[r.user_access].color, color: accessDict[r.user_access].text }}>{accessDict[r.user_access].name.en}</div></div></CellWrapper>),
                    },
                    {
                        sortKey: moment(r.created_at).format('x'),
                        key: `${JSON.stringify(r.created_at)}${r.id}`,
                        content: (<CellWrapper>{moment(r.created_at).format("MMM DD, YYYY")}</CellWrapper>)
                    },
                    {
                        key: `${r._id}${"EDIT"}`,
                        content: r._id !== globalUser._id ? (
                            <TableEdit
                                openEdit={() => openForm({ item: r })}
                                openDelete={() => openDelete({ item: r })}
                            />
                        ) : (
                                <div className="font-bold">You</div>
                            )
                    },
                ]
            }))
        ]
    }


    return (
        <div>
            <div className="flex items-center border-b justify-between w-full pb-4">
                <Header>
                    Users
            </Header>
                <GenericButton
                    onClick={() => openCreateForm({ item: null })}
                    appearance="primary" spacing="compact">
                    <div className="text-sm">
                        Create
                    </div>
                </GenericButton>
            </div>
            <Wrapper>
                <DynamicTable
                    // caption={"Unit"}
                    head={head}
                    rows={data && data.length > 0 ? TransformData() : []}
                    rowsPerPage={PAGE_SIZE}
                    defaultPage={1}
                    page={1}
                    onSetPage={() => console.log('onSetPage')}
                    loadingSpinnerSize="large"
                    isLoading={loading}
                    isFixedSize
                    defaultSortKey="term"
                    defaultSortOrder="ASC"
                    onSort={() => console.log('onSort')}
                />
            </Wrapper>
            <ModalTransition>
                {isDeleteOpen && (
                    <Modal
                        actions={[
                            { text: 'Delete', onClick: () => deleteRecord(deleteData) },
                            { text: 'No, keep it', onClick: closeDelete },
                        ]}
                        onClose={closeDelete}
                        heading={`You're about to delete user ${_.get(deleteData, 'user_id')}`}
                        appearance="danger"
                    >
                        <p>
                            Before you delete it permanently, there’s some things you should
                            know:
                            </p>
                        <ul className="pt-1">
                            <li> - This action is not reversable.</li>
                            <li> - You can always update the record rather than delete it.</li>
                        </ul>
                    </Modal>
                )}
            </ModalTransition>
            <ModalTransition>
                {isFormOpen && (
                    <Modal
                        actions={[
                            { text: `${isCreate() ? "Create" : "Update"}`, onClick: () => handleSubmit() },
                            { text: 'Dismiss', onClick: closeForm },
                        ]}
                        onClose={closeForm}
                        heading={`${isCreate() ? "Creating User" : "Updating User"}`}
                    >
                        <SelectInput
                            name={"type"}
                            label={"Type"}
                            value={values.type}
                            onSelect={handleChange}
                            options={userTypeOptions}
                        />
                        <SelectInput
                            name={"meta_type"}
                            label={"Special Type"}
                            value={values.meta_type}
                            onSelect={handleChange}
                            options={statefulMetaTypes}
                        />
                        <SelectInput
                            name={"user_access"}
                            label={"Access Pool"}
                            value={values.user_access}
                            onSelect={isCreate() ? handleChange : () => { }}
                            options={accessDictOptions}
                        />
                        <TextField
                            label={"Employee ID"}
                            value={values.user_id}
                            onChange={handleChange}
                            name={"user_id"}
                            error={errors.user_id}
                        />
                        <TextField
                            label={"Arabic Name"}
                            value={values.name.ar}
                            onChange={handleChange}
                            name={"name.ar"}
                            error={_.get(errors, "name.ar")}
                        />
                        <TextField
                            label={"English Name"}
                            value={values.name.en}
                            onChange={handleChange}
                            name={"name.en"}
                            error={_.get(errors, "name.en")}
                        />
                        <TextField
                            label={"Phone Number"}
                            value={values.phone}
                            onChange={handleChange}
                            name={"phone"}
                            error={_.get(errors, "phone")}
                        />
                        <TextField
                            label={"Email"}
                            value={values.email}
                            onChange={handleChange}
                            name={"email"}
                            error={errors.email}
                        />
                        {isCreate() && (<div className="pt-1 text-midGray text-sm">
                            Default password is 12345, Inform users that they can change in settings.
                        </div>)}
                    </Modal>
                )}
            </ModalTransition>
        </div>
    )
}




export default (Users)