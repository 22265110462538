
export default [
    {
        label: "Default",
        name: {
            en: "Default",
            ar: "مالك",
        },
        value: 0
    },
    {
        label: "Super Admin",
        name: {
            en: "Super Admin",
            ar: "مالك",
        },
        value: 1,
        disabled: true
    },
    {
        label: "Agent",
        name: {
            en: "Agent",
            ar: "عميل",
        },
        value: 2
    },
    {
        label: "Head of Engineers",
        name: {
            en: "Head of Engineers",
            ar: "رئيس المهندسين",
        },
        value: 3
    }
]