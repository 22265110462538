import React, { useState, useEffect } from "react";
import styled from "styled-components";
import DynamicTable from '@atlaskit/dynamic-table';
import { unitsApi } from "../../lib/api/units";
import moment from "moment";
import unitStatus from "../../lib/data/unitStatus";
import { FiEdit } from "react-icons/fi"
import {
    IoIosRemoveCircleOutline,
    IoMdInformationCircleOutline
} from "react-icons/io"
import TableEdit from "../../components/TableEdit";
import accessDict from "../../lib/data/accessDict";
import { withModals } from "../../lib/HOCs/withModals";
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import TextField from "../../components/TextField";
import { useFormik } from "formik";
import SelectInput from "../../components/SelectInput";
import unitBackendOptions from "../../lib/data/unitBackendOptions";
import accessDictOptions from "../../lib/data/accessDictOptions";
import GenericButton from "@atlaskit/button";
import { userApi } from "../../lib/api/user";
import _ from "lodash";
import userTypeOptions from "../../lib/data/userTypeOptions";
import jobTypeOptions from "../../lib/data/jobTypeOptions";
import { jobsApi } from "../../lib/api/jobs";
import jobType from "../../lib/data/jobType";
import SelectInputAsync from "../../components/SelectInputAsync";
import DateTimeInput from "../../components/DateTimeInput";
import TextArea from "../../components/TextArea";
import jobStatus from "../../lib/data/jobStatus";
import installationDict from "../../lib/data/installationDict";

const Header = styled.div.attrs({ className: "text-xl font-semibold tracking-wide" })``;

const Wrapper = styled.div`
  min-width: 600px;
`;
const CellWrapper = styled.div.attrs({ className: "py-3" })`

`;

const table = [
    {
        title: {
            en: "Job Status",
        },
        accessor: "status",
        wrapper: (value) => (jobStatus[value].name["en"])
    },
    {
        title: {
            en: "Created",
        },
        accessor: "created_at",
        wrapper: (value) => (moment(value).format("DD/MM/YYYY"))
    },
    {
        title: {
            en: "Required",
        },
        accessor: "required_date",
        wrapper: (value) => (moment(value).format("DD/MM/YYYY"))
    },
    {
        title: {
            en: "Delivery",
        },
        accessor: "delivery_date",
        wrapper: (value) => (moment(value).format("DD/MM/YYYY"))
    },
    {
        title: {
            en: "Finished",
        },
        accessor: "finish_date",
        wrapper: (value) => (moment(value).format("DD/MM/YYYY"))
    },
    {
        title: { en: "Unit Insallation", },
        accessor: "unit.installation_method",
        wrapper: (value) => (installationDict[value].name.en)
    },
    {
        title: { en: "Unit Serial", },
        accessor: "unit.serial_number"
    },
    {
        title: { en: "Unit Status", },
        accessor: "unit.status",
        wrapper: (value) => (unitStatus[value].name["en"])
    },

    {
        title: {
            en: "New Status",
        },
        accessor: "unit_snapshot_after.after.unit.status",
        wrapper: (value) => (unitStatus[value].name["en"])
    },
    {
        title: {
            en: "Worker",
        },
        accessor: "worker_id.name.en",
        wrapper: (value) => (_.capitalize(value))
    },
    {
        title: {
            en: "W_Number",
        },
        accessor: "worker_id.phone",
        wrapper: (value) => (_.capitalize(value))
    },
    {
        title: {
            en: "Creator",
        },
        accessor: "creator_id.name.en",
        wrapper: (value) => (_.capitalize(value))
    },
    {
        title: {
            en: "C_Number",
        },
        accessor: "creator_id.phone",
        wrapper: (value) => (_.capitalize(value))
    },
    {
        title: {
            en: "Access Pool",
        },
        accessor: "job_access",
        wrapper: (value) => (accessDict[value].name.en)
    },
    {
        title: {
            en: "Lat",
        },
        accessor: "unit.location.coordinates.0"
    },
    {
        title: {
            en: "Long",
        },
        accessor: "unit.location.coordinates.1"
    },
    {
        title: {
            en: "Job Type",
        },
        accessor: "job_type",
        wrapper: (value) => (value.map((type, index) => `${jobType[type].name["en"]}${index + 1 !== value.length ? ", " : ""}`))
    },
]

function Completed(props) {
    const [globalUser] = useState(JSON.parse(window.localStorage.getItem("USER")))
    //Modal Logic
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<any>(null);
    const getData = () => {
        return new Promise(async (resolve, reject) => {
            try {
                setLoading(true);
                let { job: _jobs } = await jobsApi.getDelivered() as any;
                console.log("USERS", _jobs)
                setData(_jobs);
                resolve()
            } catch (e) {
                reject();
                alert("Please Contact Admin")
            } finally {
                setLoading(false)
            }
        })
    }

    const onMount = async () => {
        await getData();
    }

    useEffect(() => {
        onMount()
    }, [])





    const [isDetailsOpen, setDetailsOpen] = useState(false);
    const [detailsData, setDetailsData] = useState<any>(null);
    const closeDetails = () => {
        setDetailsOpen(false);
        setDetailsData(null)
    }

    const openDetails = ({ item }) => {
        setDetailsData(item);
        setDetailsOpen(true);
    }







    //End of Modal Logic






    const head = {
        cells: [
            {
                isSortable: true,
                shouldTruncate: false,
                width: 50,
                key: "unit_serial",
                content: "Unit Serial",
            },
            {
                isSortable: true,
                shouldTruncate: false,
                width: 100,
                key: "slug",
                content: "Unit Slug",
            },
            {
                isSortable: true,
                shouldTruncate: false,
                width: 50,
                key: "Finish Date",
                content: "Finish Date",
            },
            {
                isSortable: true,
                shouldTruncate: false,
                width: 50,
                key: "required_date",
                content: "Required Date",
            },
            {
                isSortable: false,
                shouldTruncate: false,
                width: 50,
                key: "unit_change",
                content: "Unit Change",
            },
            {
                isSortable: true,
                shouldTruncate: false,
                width: 50,
                key: "worker_id",
                content: "Worker",
            },
            {
                isSortable: true,
                shouldTruncate: false,
                width: 50,
                key: "user_access",
                content: "Access Pool",
            },
            {
                isSortable: false,
                shouldTruncate: false,
                width: 50,
                key: "Edit",
                content: "Details",
            },
        ],
        key: "Hello Wrold"
    }


    const TransformData = () => {
        return [
            ...data.map((r, i) => ({
                key: `row-${i}-${r._id}`,
                cells: [
                    {
                        sortKey: r.unit_snapshot_before.before.unit.serial_number,
                        key: `${(r.unit_snapshot_before.before.unit.serial_number)}${r._id}`,
                        content: (<CellWrapper><div className="uppercase tracking-wider">{r.unit_snapshot_before.before.unit.serial_number}</div></CellWrapper>)
                    },
                    {
                        sortKey: r.unit_snapshot_after.after.unit.slug,
                        key: `${r.id}${JSON.stringify(r.unit_snapshot_after.after.unit.slug)}`,
                        content: (<CellWrapper><div className="flex"><div className="bg-gray-600 w-auto text-center text-left px-2 rounded-md text-white">{`#${r.unit_snapshot_after.after.unit.slug}`}</div></div></CellWrapper>)
                    },
                    {
                        sortKey: r.finish_date,
                        key: `${JSON.stringify(r.finish_date,)}${r.id}`,
                        content: (<CellWrapper><div className="">{moment(r.finish_date).format("MMM DD, YYYY")}</div></CellWrapper>)
                    },
                    {
                        sortKey: moment(r.required_date).format('x'),
                        key: `${JSON.stringify(r.required_date)}${r.id}`,
                        content: (<CellWrapper>{moment(r.required_date).format("MMM DD, YYYY")}</CellWrapper>)
                    },
                    {
                        sortKey: r.creator_id.name.en,
                        key: `${JSON.stringify(r.creator_id.name.en)}${r._id}`,
                        content: (<CellWrapper>{`${unitStatus[r.unit_snapshot_before.before.unit.status].name.en} : ${unitStatus[r.unit_snapshot_after.after.unit.status].name.en}`}</CellWrapper>),
                    },
                    {
                        sortKey: r.worker_id.name.en,
                        key: `${JSON.stringify(r.worker_id.name.en)}${r._id}`,
                        content: (<CellWrapper>{r.worker_id.name.en}</CellWrapper>),
                    },
                    {
                        sortKey: r.job_access,
                        key: `${r.job_access}${r._id}`,
                        content: (<CellWrapper><div className="flex"><div className="px-2 text-sm text-white rounded-md font-semibold" style={{ backgroundColor: accessDict[r.job_access].color, color: accessDict[r.job_access].text }}>{accessDict[r.job_access].name.en}</div></div></CellWrapper>),
                    },
                    {
                        key: `${r._id}${"EDIT"}`,
                        content: (
                            <div className="flex  items-center">
                                <div
                                    onClick={() => openDetails({ item: r })}
                                    className="mr-3 cursor-pointer hover:opacity-50">
                                    <IoMdInformationCircleOutline size={25} />
                                </div>
                                {/* <div>
                                    <TableEdit
                                        openEdit={() => openForm({ item: r })}
                                        openDelete={() => openDelete({ item: r })}
                                    />
                                </div> */}
                            </div>)
                    },
                ]
            }))
        ]
    }

    return (
        <div>
            <div className="flex items-center border-b justify-between w-full pb-4">
                <Header>
                    Delivered Jobs
            </Header>
                <GenericButton
                    isDisabled
                    // onClick={() => openCreateForm({ item: null })}
                    appearance="primary" spacing="compact">
                    <div className="text-sm">
                        Create
                    </div>
                </GenericButton>
            </div>
            <Wrapper>
                <DynamicTable
                    // caption={"Unit"}
                    head={head}
                    rows={data && data.length > 0 ? TransformData() : []}
                    rowsPerPage={10}
                    defaultPage={1}
                    loadingSpinnerSize="large"
                    isLoading={loading}
                    isFixedSize
                    defaultSortKey="term"
                    defaultSortOrder="ASC"
                    onSort={() => console.log('onSort')}
                    onSetPage={() => console.log('onSetPage')}
                />
            </Wrapper>
            <ModalTransition>
                {isDetailsOpen && (
                    <Modal
                        actions={[
                            { text: 'Dismiss', onClick: closeDetails },
                        ]}
                        onClose={closeDetails}
                        heading={`Job Details`}
                    >
                        {table.map(r => {
                            return (
                                <div className="flex items-center py-2 mb-1 border-b justify-between">
                                    <div className="text-primaryBlack uppercase text-sm">
                                        {r.title.en}
                                    </div>
                                    <div className="text-primaryBlack">
                                        {r.wrapper ? (
                                            <div>
                                                {r.wrapper(_.get(detailsData, r.accessor))}
                                            </div>
                                        ) : (
                                                _.get(detailsData, r.accessor)
                                            )}
                                    </div>
                                </div>
                            )
                        })}
                    </Modal>
                )}
            </ModalTransition>
        </div>
    )
}




export default (Completed)