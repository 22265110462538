import Colors from "../constants/Colors";

export default [
    {
        label: "Admin",
        value: 0,
        color: Colors.$primaryBlack
    },
    {
        label: "Engineer",
        value: 1,
        color: Colors.$midGray
    },
    {
        label: "Technician",
        value: 2,
        color: Colors.$primary
    }
]