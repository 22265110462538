import React, { Component, useState, useRef, useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import Select from "react-select";
import { userApi } from '../../lib/api/user';
import useUpdateEffect from '../../lib/hooks/useUpdateHook';
import { subtleHeading } from '@atlaskit/theme/colors';
import { fontSize, gridSize } from '@atlaskit/theme/constants';
import { headingSizes } from '@atlaskit/theme/typography';

import _ from "lodash";

interface OptionType {
    label: String;
    value: any;
}
interface Props {
    onSelect: Function;
    name;
    label;
    value;
    error?;
    optionLoader: (query) => Promise<Array<OptionType>>
}






export default function SelectInputAsync(props: Props) {
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState<any>([])
    const loadOptions = useRef(_.throttle((_data: any) => {
        return new Promise(async (resolve, reject) => {
            try {
                setLoading(true);
                let results = await (props.optionLoader(_data));
                resolve(results)
                setOptions(results);
            } catch (e) {
                console.log(e)
                reject()
            } finally {
                setLoading(false)
            }
        })
    }, 500, { leading: false, trailing: true })) as any;

    const [input, setInput] = useState("")
    // useUpdateEffect(() => {
    //     loadOptions()
    // }, [input])
    useEffect(() => { loadOptions.current(input) }, [input])
    const handleInput = text => {
        setInput(text)
    }
    return (
        <div className="w-full mb-2">
            <label
                style={{
                    fontSize: `${headingSizes.h200.size / fontSize()}em`,
                    fontStyle: 'inherit',
                    lineHeight: `${headingSizes.h200.lineHeight /
                        headingSizes.h200.size}`,
                    color: `${subtleHeading()}`,
                    fontWeight: 600,
                    marginTop: `${gridSize() * 2}px`,
                }}
            >
                {props.label}
            </label>
            <Select
                placeholder={props.value.label}
                onChange={(change) => { props.onSelect && props.onSelect({ target: { name: props.name, value: change } }) }}
                cacheOptions
                options={options}
                // loadOptions={}
                isLoading={loading}
                defaultOptions
                onInputChange={handleInput}
            />
             {props.error && (<div className="pt-1 text-sm text-darkRed">
                {props.error}
            </div>)}
        </div>
    );
}